.gallery-container {
    height: 100vh;
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;

    .gallery-banner {
        // border: 1px solid pink;
        width: 100%;
        height: 140px;
        position: relative;
        margin: 60px 0px 0px 0px;
        background-image: url("../../assets/images/gallery.jpg");
        background-position: bottom;
        background-size: cover;
        background-position-y: -400px;

        .gallery-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.4;
            z-index: 1;
        }

        .gallery-header {
            max-width: 1000px;
            // border: 1px solid red;
            margin: auto auto;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 40px;
            color: white;

            p{
                z-index: 7;
            }
        }
    }

    .gallery-items-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // min-height: 300px;
        height: max-content;
        // border: 1px solid red;
        max-width: 1200px;
        width: 1100px;
        margin: auto auto;
        // gap: 20px;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 100px;
        padding: 50px 0px;

        .gallery-item {
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            // border-radius: 20px;
            width: 500px;
            min-height: 400px;
            height: max-content;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);

            .gallery-item-cover {
                display: flex;
                overflow: hidden;
                // border: 1px solid red;
                width: 100%;
                height: 300px;
                position: relative;
                align-items: center;
                justify-content: center;

                .play-holder {
                    display: flex;
                    padding: 15px;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    border: 2px solid white;
                    opacity: 0.7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    z-index: 2;
                    cursor: pointer;

                    .play-icon {
                        width: 60px;
                        cursor: pointer;
                    }
                }

                .cover-overlay {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    // background-color: (0, 0, 0, .7);
                    background-color: black;
                    opacity: 0.37;
                    z-index: 1;
                }
                
                .video-cover {
                    object-fit: cover;
                    width: 100%;
                    // height: 100%;
                    height: 400px;
                }
            }

            .media-icon {
                position: absolute;
                z-index: 2;
                width: 35px;
                height: 35px;
            }

            .gallery-item-description {
                // border: 1px solid red;
                display: flex;
                z-index: 2;
                flex-direction: column;

                .caption {
                    z-index: 2;
                    // border: 1px solid blue;
                    background-color: #0C4CAC;
                    color: white;
                    display: flex;
                    width: max-content;
                    padding: 10px;
                    margin: -43px 0px 0px 0px;
                }

                .text {
                    display: flex;
                    z-index: 2;
                    // border: 1px solid blue;
                    padding: 10px;
                }
            }
        }

    }

    .video-player-wrapper {
        position: fixed;
        // top: 10px;
        width: 100%;
        height: 100vh;
        top: -10px;
        background-color: rgba(0, 0, 0, .5);
        z-index: 13;
        // margin: 0px auto 0px auto;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;

        .video-player {
            position: relative;
            margin: 0px auto 0px auto;
    
            .close-wrapper {
                width: 20px;
                height: 20px;
                background-color:#0C4CAC;
                padding: 15px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                right: 10px;
                position: absolute;
                z-index: 1;
    
                .close-btn {
                    width: 45px;
                }
            }

            video {
                width: 950px;
                margin: 0px auto 0px auto;
            }
        }

        .video-player.inactive {
            display: none;
        }

    }

    .video-player-wrapper.inactive {
        display: none;
    }

    .gallery-nothing {

        .gallery-wrapper {

            display: flex;
            flex-direction: column;
            // border: 1px solid black;
            border-radius: 50%;
            width: 500px;
            height: 500px;
            padding: 20px;
            margin: 30px auto 100px auto;
            background-color: #fafafa;

            .gallery-image {
                // background-image: url('../../assets/images/nopost.png');
                height: 350px;
                width: 350px;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                // border: 1px solid red;
                margin: 0px auto 0px auto;

                img {
                    width: 300px;
                    margin: 0px auto 0px auto;
                    // border: 1px solid red;
                }
            }

            .gallery-text {
                text-align: center;
                p:nth-child(1) {
                    font-size: 25px;
                    margin-bottom: 30px;
                }
                p:nth-child(2) {
                    font-size: 15px;
                    opacity: 0.4;
                    width: 250px;
                    margin: 0px auto 0px auto;
                }
            }
            
        }
    }

    // .gallery-journey {

    //     margin: 50px;

    //     .gallery-journey-wrapper {
    //         max-width: 1300px;
    //         // border: 1px solid red;
    //         height: 300px;
    //         margin: 0px auto;
    //         display: flex;
    //         gap: 20px;

    //         .gallery-journey-left {
    //             width: 50%;
    //             height: 100%;
    //             background-image: url("../../assets/images/headoffice.jpg");
    //             background-size: cover;
    //         }
    //         .gallery-journey-right {
    //             width: 50%;

    //             .gallery-journey-header {
    //                 font-size: 30px;

    //                 hr {
    //                     margin: 10px 0px 10px 0px;
    //                     width: 40px;
    //                     border: 1px solid #c62828;
    //                 }
    //             }

    //             p {
    //                 font-weight: 300;
    //             }
    //         }
    //     }
    // }
}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .gallery-container {
            // display: flex;
            // border: 1px solid black;
        .gallery-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .gallery-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .gallery-items-wrapper {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // min-height: 300px;
            height: max-content;
            // border: 1px solid red;
            max-width: 1200px;
            width: max-content;
            margin: auto auto;
            // gap: 20px;
            align-items: center;
            justify-content: space-around;
            margin-top: 0px;
            margin-bottom: 100px;
            padding: 50px 0px;
    
            .gallery-item {
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                // border-radius: 20px;
                width: 300px;
                min-height: 400px;
                height: max-content;
                overflow: hidden;
                position: relative;
                margin-bottom: 20px;
                box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
    
                .gallery-item-cover {
                    display: flex;
                    overflow: hidden;
                    // border: 1px solid red;
                    width: 100%;
                    height: 300px;
                    position: relative;
                    align-items: center;
                    justify-content: center;
    
                    .play-holder {
                        display: flex;
                        padding: 15px;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        border: 2px solid white;
                        opacity: 0.7;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
    
                        .play-icon {
                            width: 60px;
                            cursor: pointer;
                        }
                    }
    
                    .cover-overlay {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        // background-color: (0, 0, 0, .7);
                        background-color: black;
                        opacity: 0.37;
                        z-index: 1;
                    }
                    
                    .video-cover {
                        object-fit: cover;
                        width: 100%;
                        // height: 100%;
                        height: 400px;
                    }
                }
    
                .media-icon {
                    position: absolute;
                    z-index: 2;
                    width: 35px;
                    height: 35px;
                }
    
                .gallery-item-description {
                    // border: 1px solid red;
                    display: flex;
                    z-index: 2;
                    flex-direction: column;
    
                    .caption {
                        z-index: 2;
                        // border: 1px solid blue;
                        background-color: #0C4CAC;
                        color: white;
                        display: flex;
                        width: max-content;
                        padding: 10px;
                        margin: -43px 0px 0px 0px;
                    }
    
                    .text {
                        display: flex;
                        z-index: 2;
                        // border: 1px solid blue;
                        padding: 10px;
                    }
                }
            }
    
        }
    
        .video-player-wrapper {
            position: fixed;
            // top: 10px;
            width: 100%;
            height: 100vh;
            top: -10px;
            background-color: rgba(0, 0, 0, .5);
            z-index: 13;
            // margin: 0px auto 0px auto;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
    
            .video-player {
                position: relative;
                margin: 0px auto 0px auto;
        
                .close-wrapper {
                    width: 20px;
                    height: 20px;
                    background-color:#0C4CAC;
                    padding: 15px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 10px;
                    right: 10px;
                    position: absolute;
                    z-index: 1;
        
                    .close-btn {
                        width: 45px;
                    }
                }
    
                video {
                    width: 300px;
                    margin: 0px auto 0px auto;
                }
            }
    
            .video-player.inactive {
                display: none;
            }
    
        }
    
        .video-player-wrapper.inactive {
            display: none;
        }

        .gallery-nothing {

            .gallery-wrapper {
    
                display: flex;
                flex-direction: column;
                // border: 1px solid black;
                border-radius: 50%;
                width: 300px;
                height: 300px;
                padding: 20px;
                margin: 30px auto 100px auto;
                background-color: #fafafa;
    
                .gallery-image {
                    // background-image: url('../../assets/images/nopost.png');
                    height: 300px;
                    width: 300px;
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid red;
                    margin: 0px auto 0px auto;
    
                    img {
                        width: 170px;
                        margin: 0px auto 0px auto;
                        // border: 1px solid red;
                    }
                }
    
                .gallery-text {
                    text-align: center;
                    p:nth-child(1) {
                        font-size: 25px;
                        margin-top: 10px;
                    }
                    p:nth-child(2) {
                        font-size: 13px;
                        opacity: 0.4;
                        width: 250px;
                        margin: 0px auto 20px auto;
                    }
                }
                
            }
        }
    }
}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .gallery-container {
            // display: flex;
            // border: 1px solid black;
        .gallery-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .gallery-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .gallery-nothing {

            .gallery-wrapper {
    
                display: flex;
                flex-direction: column;
                // border: 1px solid black;
                border-radius: 50%;
                width: 300px;
                height: 300px;
                padding: 20px;
                margin: 30px auto 100px auto;
                background-color: #fafafa;
    
                .gallery-image {
                    // background-image: url('../../assets/images/nopost.png');
                    height: 300px;
                    width: 300px;
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid red;
                    margin: 0px auto 0px auto;
    
                    img {
                        width: 170px;
                        margin: 0px auto 0px auto;
                        // border: 1px solid red;
                    }
                }
    
                .gallery-text {
                    text-align: center;
                    p:nth-child(1) {
                        font-size: 25px;
                        margin-top: 10px;
                    }
                    p:nth-child(2) {
                        font-size: 13px;
                        opacity: 0.4;
                        width: 250px;
                        margin: 0px auto 30px auto;
                    }
                }
                
            }
        }
    }
}