.add-container {
    z-index: 1;
    position: fixed;
    right: 60px;
    bottom: 40px;
    cursor: pointer;
    /* margin-bottom: -50px; */

    .add-link {
        text-decoration: none;

        .add-button {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            /* font-size: 70px; */
            font-size: 45px;
            background-color: #0C4CAC;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgb(0 0 0 / 45%) 0px 6px 30px 0px;
        }
    }
}

.notificationBot-container {
    z-index: 3;
    position: fixed;
    right: 60px;
    bottom: 40px;
    cursor: pointer;
    /* margin-bottom: -50px; */

    // .add-link {
    //     text-decoration: none;

        .notificationBot-wrapper {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            /* font-size: 70px; */
            font-size: 45px;
            // background-color: #0C4CAC;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgb(0 0 0 / 45%) 0px 6px 30px 0px;
        }
    // }
}

.chatusbutton-container {
    border:  1px solid red;
}

@media screen and (max-width: 480px) {
    .add-container {
        z-index: 1;
        position: fixed;
        right: 10px;
        bottom: 70px;
    }    

    .add-button {
        width: 40px;
        height: 40px;
        /* font-size: 40px; */
        font-size: 30px;
    }
}