
.hero-container {
    height: 70vh;
    // border: 1px solid black;
    display: flex;
    padding-top: 60px;

        .hero-left {
            width: 45%;
            // border: 1px solid black;
            display: flex;
            flex-direction: column;
            justify-content: center;

            
            .hero-left-wrapper {
                // border: 1px solid black;
                padding: 0px 120px;
                height: max-content;
                display: flex;
                flex-direction: column;
                // background-color: rgba(241, 241, 241, .9);
                // gap: 20px;

                .hero-caption {
                    font-size: 30px;
                    // width: 50%;
                    span {
                        font-size: 60px;
                        font-weight: 500;
                        color: #0C4CAC;
                    }
                    // border: 1px solid black;
                    
                    animation-name: slideUp;
                    animation-duration: 1.2s;
                }

                @keyframes slideUp--one {
                    from {
                        transform: translateY(0px);
                        opacity: 0;
                    }

                    to {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }


                .hero-description {
                    width: 350px;
                    line-height: 30px;
                    height: max-content;
                    color: #838383;
                    animation-name: slideUp;
                    animation-duration: 1.2s;
                    animation-timing-function: ease-out;

                }

                @keyframes slideUp {
                    from {
                        transform: translateY(100px);
                        opacity: 0;
                    }

                    to {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }

                .hero-cta {
                    padding: 20px 50px;
                    background-color: #0C4CAC;
                    width: max-content;
                    color: white;
                    border-radius: 20em;
                    cursor: pointer;
                    margin-top: 20px;
                }
                
            }
        }
    .hero-right {
        width: 55%;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            // width: 400px;
            // height: 350px;
            width: 650px;
            height: 450px;
            
        }

    }
}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .hero-container {
        height: 65vh;
        // height: max-content;
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        // border: 1px solid black;
        margin: 0px 0px 110px 0px;

        .hero-right {
            // border: 1px solid red;
            width: 100%;
            // margin: 0px auto -170px auto;
            margin: 0px auto 170px auto;
            img {
                // width: 300px;
                width: 100%;
                height: 250px;
                // margin: 500px 0px 0px 200px;
            }
        }

        .hero-left {
            position: absolute;
            // margin: 0px 0px -400px 0px;
            margin: 0px 0px -110px 0px;
            // border: 1px solid red;
            width: 100%;

            .hero-left-wrapper {

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(241, 241, 241, .9);
                padding: 0px 0px 40px 0px;

                .hero-caption {
                    font-size: 20px;
                    // border: 1px solid red;
                    width: 250px;
                    text-align: center;
                    // width: 50%;
                    span {
                        font-size: 40px;
                        font-weight: 500;
                        color: #0C4CAC;
                    }
                }

                .hero-description {
                    padding: 0px 10px;
                    text-align: center;
                }
            }
            // visibility: hidden;
        }
    }

}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .hero-container {
        height: 55vh;
        // height: max-content;
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        // border: 1px solid black;
        margin: 0px 0px 110px 0px;

        .hero-right {
            // border: 1px solid red;
            // margin: 0px auto -170px auto;
            width: 100%;
            margin: 0px auto 170px auto;
            img {
                // width: 300px;
                width: 100%;
                height: 250px;
                // margin: 500px 0px 0px 200px;
            }
        }

        .hero-left {
            position: absolute;
            // margin: 0px 0px -400px 0px;
            margin: 0px 0px -130px 0px;
            // border: 1px solid red;
            width: 100%;

            .hero-left-wrapper {

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(241, 241, 241, .9);
                padding: 0px 0px 40px 0px;

                .hero-caption {
                    font-size: 20px;
                    // border: 1px solid red;
                    width: 250px;
                    text-align: center;
                    // width: 50%;
                    span {
                        font-size: 40px;
                        font-weight: 500;
                        color: #0C4CAC;
                    }
                }

                .hero-description {
                    padding: 0px 10px;
                    text-align: center;
                }
            }
            // visibility: hidden;
        }
    }

}

@media only screen and (min-width: 451px) and (max-width: 800px){ //Mobile

    .hero-container {
        height: 55vh;
        // height: max-content;
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        // border: 1px solid black;
        margin: 0px 0px 110px 0px;

        .hero-right {
            // border: 1px solid red;
            // margin: 0px auto -170px auto;
            width: 100%;
            margin: 0px auto 170px auto;
            img {
                // width: 300px;
                width: 100%;
                height: 450px;
                // margin: 500px 0px 0px 200px;
            }
        }

        .hero-left {
            position: absolute;
            // margin: 0px 0px -400px 0px;
            margin: 0px 0px -110px 0px;
            // border: 1px solid red;
            width: 100%;

            .hero-left-wrapper {

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(241, 241, 241, .9);
                padding: 20px 0px 40px 0px;
                width: 90%;
                margin: 0px auto -10px auto;

                .hero-caption {
                    font-size: 25px;
                    // border: 1px solid red;
                    width: 100%;
                    text-align: center;
                    // width: 50%;
                    span {
                        font-size: 40px;
                        font-weight: bold;
                        color: #0C4CAC;
                    }
                }

                .hero-description {
                    padding: 0px 10px;
                    text-align: center;
                    width: 60%;
                    // border: 1px solid red;
                }
            }
            // visibility: hidden;
        }
    }

}


@media only screen and (min-width: 801px) and (max-width: 960px){ //Mobile

    .hero-container {
        height: 55vh;
        // height: max-content;
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        // border: 1px solid black;
        margin: 0px 0px 110px 0px;

        .hero-right {
            // border: 1px solid red;
            // margin: 0px auto -170px auto;
            width: 100%;
            margin: 0px auto 170px auto;
            img {
                // width: 300px;
                width: 100%;
                height: 450px;
                // margin: 500px 0px 0px 200px;
            }
        }

        .hero-left {
            position: absolute;
            // margin: 0px 0px -400px 0px;
            margin: 0px 0px -110px 0px;
            // border: 1px solid red;
            width: 100%;

            .hero-left-wrapper {

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(241, 241, 241, .9);
                padding: 20px 0px 40px 0px;
                width: 90%;
                margin: 0px auto -10px auto;

                .hero-caption {
                    font-size: 25px;
                    // border: 1px solid red;
                    width: 100%;
                    text-align: center;
                    // width: 50%;
                    span {
                        font-size: 40px;
                        font-weight: bold;
                        color: #0C4CAC;
                    }
                }

                .hero-description {
                    padding: 0px 10px;
                    text-align: center;
                    width: 60%;
                    // border: 1px solid red;
                }
            }
            // visibility: hidden;
        }
    }

}


// @media only screen and (min-width: 961px) and (max-width: 1600px){ //Mobile

//     .hero-container {
//         height: 55vh;
//         // height: max-content;
//         display: flex;
//         flex-direction: column-reverse;
//         background-color: white;
//         // border: 1px solid black;
//         margin: 0px 0px 110px 0px;

//         .hero-right {
//             // border: 1px solid red;
//             // margin: 0px auto -170px auto;
//             width: 100%;
//             margin: 0px auto 170px auto;
//             img {
//                 // width: 300px;
//                 width: 100%;
//                 height: 450px;
//                 // margin: 500px 0px 0px 200px;
//             }
//         }

//         .hero-left {
//             position: absolute;
//             // margin: 0px 0px -400px 0px;
//             margin: 0px 0px -110px 0px;
//             // border: 1px solid red;
//             width: 100%;

//             .hero-left-wrapper {

//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 background-color: rgba(241, 241, 241, .9);
//                 padding: 20px 0px 40px 0px;
//                 width: 90%;
//                 margin: 0px auto -10px auto;

//                 .hero-caption {
//                     font-size: 25px;
//                     // border: 1px solid red;
//                     width: 100%;
//                     text-align: center;
//                     // width: 50%;
//                     span {
//                         font-size: 40px;
//                         font-weight: bold;
//                         color: #0C4CAC;
//                     }
//                 }

//                 .hero-description {
//                     padding: 0px 10px;
//                     text-align: center;
//                     width: 60%;
//                     // border: 1px solid red;
//                 }
//             }
//             // visibility: hidden;
//         }
//     }

// }