.sportal-container {
    display: flex;
    flex-direction: column;

    .sportal-wrapper {
        height: max-content;
        margin: 60px 0px 0px 0px;
        // border: 1px solid black;
        display: flex;
        flex-direction: row;

        .sportal-sidenav {
            height: 100%;
            width: 60px;
            // border: 1px solid blue;
        }

        .sportal-mobile {
            display: none;
        }

        .sportal-body {
            // height: 100vh;
            height: max-content;
            width: 100%;
            // border: 1px solid orange;
            background-color: #fafafa;

            .sportal-body-header {
                display: flex;
                flex-direction: row;
                // align-items: center;
                justify-content: space-around;
                height: 150px;
                width: 100%;
                margin: 0px 0px 0px 0px;
                // border: 1px solid orange;
                // background-color: #0f5bce;
                background-color: #0C4CAC;
                border-radius: 0px 0px 20px 20px;
                position: relative;

                .page-title {
                    display: flex;
                    position: absolute;
                    color: #fff;
                    margin: 10px 0px 0px 20px;
                    // border: 1px solid white;
                    left: 0px;
                    top: 0px;
                }

                .name-space {
                    display: flex;
                    position: absolute;
                    color: #fff;
                    margin: 10px 20px 0px 0px;
                    font-size: 14px;
                    top: 0px;
                    right: 0px;

                }
                .search-bar {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    right: 0px;
                    margin: 70px 17px 0px 0px;
                    // position: fixed;
                    // z-index: 10;

                    input:focus, textarea:focus, select:focus {
                        outline: #0C4CAC;
                        border: 1px solid #0C4CAC;
                    }

                    .search-icon {
                        position: absolute;
                        margin: 11px 0px 0px 12px;
                        // margin: 12px 0px 0px 12px;
                        color: #e0e0e0;
                    }
                    .formInput {
                        width: 220px;
                        // padding: 15px 10px 15px 45px;
                        padding: 12px 10px 12px 39px;
                        border-radius: 20px;
                        // border-radius: 2em;
                        border: 1px solid #ececec;


                    }
                }

                .booking-category {
                    position: absolute;
                    display: flex;
                    width: max-content;
                    overflow: hidden;
                    height: max-content;
                    background-color: #063b8a;
                    left: 0px;
                    margin: 70px 0px 0px 20px;
                    border-radius: 30px;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;

                    .category-item {
                        width: max-content;
                        // border: 1px solid red;
                        padding: 10px 20px 10px 20px;
                        color: #fff;
                        cursor: pointer;

                    }

                    .category-item.active {
                        background-color: #fff;
                        border-radius: 2em;
                        color: #0C4CAC;
                    }
                }

                .sorting {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    // align-items: center;
                    // justify-content: center;
                    // border: 1px solid blue;

                    .sorting-grid {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        width: 40px;
                        // border: 1px solid blue;
                        border-radius: 50%;
                        background-color: white;
                        color: #0C4CAC;
                        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                        cursor: pointer;
                    }
                    .sorting-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        width: 40px;
                        // border: 1px solid blue;
                        border-radius: 50%;
                        // background-color: white;
                        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                        cursor: pointer;
                    }
                }
            }

            .sportal-body-body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // border: 1px solid orange;

                .booking-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 10px;
                    gap: 15px;
                    align-items: center;
                    justify-content: center;
                    // margin-bottom: 300px;
                    margin: -30px 0px 150px 0px;

                    .booking-item {
                        border: 1px solid #EEEEEE;
                        padding: 15px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 18px;
                        width: 270px;
                        height: 135px;
                        position: relative;
                        background-color: white;

                        // .booking-orderno {
                        //     background-color:#0f5bce;
                        //     // color: white;
                        //     color: #c4dff5;
                        //     width: max-content;
                        //     border-radius: 20px;
                        //     padding: 2px 10px;
                        // }

                        .booking-service {
                            color: #8f8f8f;
                            font-weight: 500;
                            // border: 1px solid red;
                            width: 230px;
                            font-size: 13px;
                        }

                        .booking-user {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            color: #757575;
                            font-size: 12px;

                            span {
                                color: #2196f3;
                            }
                        }

                        .booking-phone {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            color: #757575;
                            font-size: 12px;

                            span {
                                color: #2196f3;
                            }
                        }

                        .booking-date {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            color: #757575;
                            font-size: 12px;

                            span {
                                color: #2196f3;
                            }
                        }

                        .booking-amount {
                            position: absolute;
                            margin: 35px auto 0px 190px;
                            font-size: 18px;
                        }

                        .booking-status {
                            position: absolute;
                            // margin: 65px auto 0px 205px;
                            margin: 110px auto 0px 200px;
                            font-size: 12px;
                            // border: 1px solid black;
                            padding: 4px 8px;
                            border-radius: 20px;
                        }

                        .booking-status.success {
                            background-color: #3cd4cc;
                            color: #fff;
                        }

                        .booking-status.pending {
                            background-color: #f49b09;
                            color: #fff;
                        }

                        .booking-status.cancelled {
                            background-color: #ec6494;
                            color: #fff;
                        }

                        .booking-status.refund {
                            background-color: #2196f3;
                            color: #fff;
                        }

                        .booking-modal {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            padding: 5px;
                            border: 1px solid #2196f3;
                            color: #2196f3;
                            position: absolute;
                            margin: 105px auto 0px 235px;
                        }

                    }

                }
            }
        }
    }

}



@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .sportal-container {
        display: flex;
        flex-direction: column;
    
        .sportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .sportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .sportal-mobile {
                display: block;
            }
    
            .sportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .sportal-body-header {
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    justify-content: space-around;
                    height: 150px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    // border: 1px solid orange;
                    // background-color: #0f5bce;
                    background-color: #0C4CAC;
                    border-radius: 0px 0px 20px 20px;
                    position: relative;
    
                    .page-title {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 0px 0px 20px;
                        // border: 1px solid white;
                        left: 0px;
                        top: 0px;
                    }
    
                    .name-space {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 20px 0px 0px;
                        font-size: 14px;
                        top: 0px;
                        right: 0px;
    
                    }
                    .search-bar {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        right: 0px;
                        margin: 35px 17px 0px 0px;
                        // position: fixed;
                        // z-index: 10;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 11px 0px 0px 12px;
                            // margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 0px;
                            // padding: 15px 10px 15px 45px;
                            padding: 10px 10px 10px 39px;
                            border-radius: 20px;
                            // border-radius: 2em;
                            border: 1px solid #ececec;
                            transition: 0.8s;
    
                        }

                        .formInput:hover {
                            width: 200px;
                        }

                        .formInput:focus {
                            width: 200px;
                        }
                    }
    
                    .booking-category {
                        position: absolute;
                        display: flex;
                        width: max-content;
                        overflow: hidden;
                        height: max-content;
                        background-color: #063b8a;
                        // left: 0px;
                        margin: 80px auto 0px 20px;
                        border-radius: 30px;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
    
                        .category-item {
                            width: max-content;
                            // border: 1px solid red;
                            padding: 10px 20px 10px 20px;
                            color: #fff;
                            cursor: pointer;
    
                        }
    
                        .category-item.active {
                            background-color: #fff;
                            border-radius: 2em;
                            color: #0C4CAC;
                        }
                    }
    
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .sportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
                        margin: -30px 0px 150px 0px;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            width: 270px;
                            height: 120px;
                            position: relative;
                            background-color: white;
    
                            // .booking-orderno {
                            //     background-color:#0f5bce;
                            //     // color: white;
                            //     color: #c4dff5;
                            //     width: max-content;
                            //     border-radius: 20px;
                            //     padding: 2px 10px;
                            // }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 230px;
                                font-size: 13px;
                            }
    
                            .booking-user {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-phone {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-date {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 190px;
                                font-size: 18px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 100px auto 0px 200px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 4px 8px;
                                border-radius: 20px;
                            }
    
                            .booking-status.success {
                                background-color: #3cd4cc;
                                color: #fff;
                            }
    
                            .booking-status.pending {
                                background-color: #f49b09;
                                color: #fff;
                            }
    
                            .booking-status.cancel {
                                background-color: #ec6494;
                                color: #fff;
                            }
    
                            .booking-status.refund {
                                background-color: #2196f3;
                                color: #fff;
                            }
    
                            // .booking-modal {
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: center;
                            //     width: 20px;
                            //     height: 20px;
                            //     border-radius: 50%;
                            //     padding: 5px;
                            //     border: 1px solid #2196f3;
                            //     color: #2196f3;
                            //     position: absolute;
                            //     margin: 105px auto 0px 235px;
                            // }
    
                        }
    
                    }
                }
            }
        }
    
    }
    

}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .sportal-container {
        display: flex;
        flex-direction: column;
    
        .sportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .sportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .sportal-mobile {
                display: block;
            }
    
            .sportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .sportal-body-header {
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    justify-content: space-around;
                    height: 150px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    // border: 1px solid orange;
                    // background-color: #0f5bce;
                    background-color: #0C4CAC;
                    border-radius: 0px 0px 20px 20px;
                    position: relative;
    
                    .page-title {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 0px 0px 20px;
                        // border: 1px solid white;
                        left: 0px;
                        top: 0px;
                    }
    
                    .name-space {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 20px 0px 0px;
                        font-size: 14px;
                        top: 0px;
                        right: 0px;
    
                    }
                    .search-bar {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        right: 0px;
                        margin: 35px 17px 0px 0px;
                        // position: fixed;
                        // z-index: 10;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 11px 0px 0px 12px;
                            // margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 0px;
                            // padding: 15px 10px 15px 45px;
                            padding: 10px 10px 10px 39px;
                            border-radius: 20px;
                            // border-radius: 2em;
                            border: 1px solid #ececec;
                            transition: 0.8s;
    
                        }

                        .formInput:hover {
                            width: 200px;
                        }

                        .formInput:focus {
                            width: 200px;
                        }
                    }
    
                    .booking-category {
                        position: absolute;
                        display: flex;
                        width: max-content;
                        overflow: hidden;
                        height: max-content;
                        background-color: #063b8a;
                        // left: 0px;
                        margin: 80px auto 0px 20px;
                        border-radius: 30px;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
    
                        .category-item {
                            width: max-content;
                            // border: 1px solid red;
                            padding: 10px 20px 10px 20px;
                            color: #fff;
                            cursor: pointer;
    
                        }
    
                        .category-item.active {
                            background-color: #fff;
                            border-radius: 2em;
                            color: #0C4CAC;
                        }
                    }
    
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .sportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
                        margin: -30px 0px 150px 0px;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            width: 270px;
                            height: 120px;
                            position: relative;
                            background-color: white;
    
                            // .booking-orderno {
                            //     background-color:#0f5bce;
                            //     // color: white;
                            //     color: #c4dff5;
                            //     width: max-content;
                            //     border-radius: 20px;
                            //     padding: 2px 10px;
                            // }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 230px;
                                font-size: 13px;
                            }
    
                            .booking-user {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-phone {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-date {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 190px;
                                font-size: 18px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 100px auto 0px 200px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 4px 8px;
                                border-radius: 20px;
                            }
    
                            .booking-status.success {
                                background-color: #3cd4cc;
                                color: #fff;
                            }
    
                            .booking-status.pending {
                                background-color: #f49b09;
                                color: #fff;
                            }
    
                            .booking-status.cancel {
                                background-color: #ec6494;
                                color: #fff;
                            }
    
                            .booking-status.refund {
                                background-color: #2196f3;
                                color: #fff;
                            }
    
                            // .booking-modal {
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: center;
                            //     width: 20px;
                            //     height: 20px;
                            //     border-radius: 50%;
                            //     padding: 5px;
                            //     border: 1px solid #2196f3;
                            //     color: #2196f3;
                            //     position: absolute;
                            //     margin: 105px auto 0px 235px;
                            // }
    
                        }
    
                    }
                }
            }
        }
    
    } 
}

@media only screen and (min-width: 451px) and (max-width: 700px){ //Mini Tablet

    .sportal-container {
        display: flex;
        flex-direction: column;
    
        .sportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .sportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .sportal-mobile {
                display: block;
            }
    
            .sportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .sportal-body-header {
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    justify-content: space-around;
                    height: 150px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    // border: 1px solid orange;
                    // background-color: #0f5bce;
                    background-color: #0C4CAC;
                    border-radius: 0px 0px 20px 20px;
                    position: relative;
    
                    .page-title {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 0px 0px 20px;
                        // border: 1px solid white;
                        left: 0px;
                        top: 0px;
                    }
    
                    .name-space {
                        display: flex;
                        position: absolute;
                        color: #fff;
                        margin: 10px 20px 0px 0px;
                        font-size: 14px;
                        top: 0px;
                        right: 0px;
    
                    }
                    .search-bar {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        right: 0px;
                        margin: 35px 17px 0px 0px;
                        // position: fixed;
                        // z-index: 10;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 11px 0px 0px 12px;
                            // margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 0px;
                            // padding: 15px 10px 15px 45px;
                            padding: 10px 10px 10px 39px;
                            border-radius: 20px;
                            // border-radius: 2em;
                            border: 1px solid #ececec;
                            transition: 0.8s;
    
                        }

                        .formInput:hover {
                            width: 200px;
                        }

                        .formInput:focus {
                            width: 200px;
                        }
                    }
    
                    .booking-category {
                        position: absolute;
                        display: flex;
                        width: max-content;
                        overflow: hidden;
                        height: max-content;
                        background-color: #063b8a;
                        // left: 0px;
                        margin: 80px auto 0px 20px;
                        border-radius: 30px;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
    
                        .category-item {
                            width: max-content;
                            // border: 1px solid red;
                            padding: 10px 20px 10px 20px;
                            color: #fff;
                            cursor: pointer;
    
                        }
    
                        .category-item.active {
                            background-color: #fff;
                            border-radius: 2em;
                            color: #0C4CAC;
                        }
                    }
    
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .sportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
                        margin: -30px 0px 150px 0px;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            width: 230px;
                            height: 120px;
                            position: relative;
                            background-color: white;
    
                            // .booking-orderno {
                            //     background-color:#0f5bce;
                            //     // color: white;
                            //     color: #c4dff5;
                            //     width: max-content;
                            //     border-radius: 20px;
                            //     padding: 2px 10px;
                            // }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 230px;
                                font-size: 13px;
                            }
    
                            .booking-user {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-phone {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-date {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #757575;
                                font-size: 12px;
    
                                span {
                                    color: #2196f3;
                                }
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 150px;
                                font-size: 18px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 100px auto 0px 170px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 4px 8px;
                                border-radius: 20px;
                            }
    
                            .booking-status.success {
                                background-color: #3cd4cc;
                                color: #fff;
                            }
    
                            .booking-status.pending {
                                background-color: #f49b09;
                                color: #fff;
                            }
    
                            .booking-status.cancel {
                                background-color: #ec6494;
                                color: #fff;
                            }
    
                            .booking-status.refund {
                                background-color: #2196f3;
                                color: #fff;
                            }
    
                            // .booking-modal {
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: center;
                            //     width: 20px;
                            //     height: 20px;
                            //     border-radius: 50%;
                            //     padding: 5px;
                            //     border: 1px solid #2196f3;
                            //     color: #2196f3;
                            //     position: absolute;
                            //     margin: 105px auto 0px 235px;
                            // }
    
                        }
    
                    }
                }
            }
        }
    
    }
}