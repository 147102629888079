.supportengine-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    // border: 1px solid green;
    width: 100px;
    height: 100px;
    // background-color: green;
    z-index: 1;
    position: fixed; 
    bottom: 24px;
    right: 24px
}

.avatar-container {

    .avatarHello { 
        // Position
        position: absolute;
        // left: calc(-100% - 44px - 28px);
        // top: calc(50% - 24px);
        bottom: 40px;
        right: 114px;
        // Layering
        z-index: 10000;
        box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
        // Border
        padding: 12px 12px 12px 16px;
        border-radius: 24px;
        // Color
        background-color: #f9f0ff;
        // background-color: #fff;
        color: 'black';
        width: max-content;
    }

    .avatarHello.hovered {
        opacity: 1;
    }

    .avatarHello.nothovered {
        opacity: 0;
    }
    
    .chatWithMeButton {
        cursor: pointer;
        // box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
        // Border
        border-radius: 50%;
        // Background 
        //backgroundImage: `url(https://chat-engine-assets.s3.amazonaws.com/tutorials/my-face-min.png)`, 
        background-repeat: no-repeat;
        background-position: center;
        background-size: 84px;
        background-color: #0C4CAC;
        // Size
        width: 84px;
        height: 84px;
        animation: pulse 2s infinite;

        .chatIcon {
            // Size
            width: 50px;
            height: 50px;
            margin: 15px 15px;
        }
    }
    
    .chatWithMeButton.pulse {
        background: rgb(23, 86, 157);
        box-shadow: 0 0 0 0 rgba(23, 86, 157, 1);
        animation: pulse 2s infinite;
    }
    
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(23, 86, 157, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(23, 86, 157, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(23, 86, 157, 0);
        }
    }

}

.whatsapp-container {
    display: flex;
    position: absolute;
    height: 60px;
    width: 60px;
    // border: 1px solid red;
    z-index: 1;
    // position: fixed; 
    bottom: 118px;
    right: 30px;
    align-items: center;
    justify-content: center;

    .waIcon {
        cursor: pointer;
        // border: 1px solid red;
        // Size
        width: 60px;
        height: 60px;
        // margin: 15px 15px;
        // z-index: 1;
        // position: fixed; 
    }
}

.whatsapp-container.show {
    display: inline-block;
}

.whatsapp-container.hide {
    display: none;
}


.livechat-container {
    display: flex;
    position: absolute;
    border-radius: 50%;
    // border: 1px solid red;
    height: 60px;
    width: 60px;
    bottom: 190px;
    right: 30px;
    background: rgb(23, 86, 157);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #f9f0ff;

    .liveIcon {
        cursor: pointer;
        // Size
        width: 50px;
        height: 50px;
        margin: 17px 15px 15px 17px;
        z-index: 1;
        // position: fixed; 
        // bottom: 124px;
        // right: 44px
    }
}

.livechat-container.show {
    display: inline-block;
}

.livechat-container.hide {
    display: none;
}

.supportwindow-container {
    display: flex;
    // Position
    position: fixed;
    bottom: 10px;
    right: 24px;
    // Size
    width: 350px;
    // height: 530px;
    height: 500px;
    max-width: calc(100% - 48px);
    max-height: calc(100% - 48px);
    background-color: white;
    // Border
    border-radius: 12px;
    border: 2px solid #0C4CAC;
    // overflow: hidden;
    // Shadow
    box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
    z-index: 2;

    .closeWindow {
        // border: 1px solid red;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        position: absolute;
        right: 0px;
        top: -70px;
        background-color:  rgb(23, 86, 157);
        // bottom: 116px;
        // right: 24px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

    }

}

.emailform-container {
    display: flex;
    flex-direction: column;
    width: 100%; 
    overflow: hidden;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    // border: 1px solid red;

    .emailform-stripe {
        position: absolute;
        top: -45px;
        left: 0px;
        // top: -45px;
        width: 100%;
        height: 120px;
        background-color:  rgb(23, 86, 157);
        transform: skewY(-4deg);
    }

    .loading-wrapper { 
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center; 
        background-color: white;
        // border: 1px solid red;

        .loading-icon {
            color:  rgb(23, 86, 157);
            position: absolute;
            top: calc(50% - 51px); 
            left: calc(50% - 51px); 
            font-weight: 600;
            font-size: 82px;
    
            /*Transition - 5*/
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
    
        }
    
        .loading-icon.active {
            z-index: 10;
            opacity: 1;
        }
        .loading-icon.inactive {
            z-index: -1;
            opacity: 0;
        }
    }

    .loading-wrapper.active {
        z-index: 10;
        opacity: 0.9;
    }
    .loading-wrapper.inactive {
        z-index: -1;
        opacity: 0;
        display: none;
    }

    .emailform-toptext {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 40px;
        // top: 15%; 
        color: white; 
        font-size: 15px; 
        // font-weight: 600;
        // border: 1px solid red;
        padding: 15px 30px;
        gap: 10px;

        .left {
            width: 40px;
            height: 40px;

            img {
                width: 40px;
            }
        }

        .right {
            width: 40%;
            font-size: 13px;
            font-weight: 100;
            // border: 1px solid red;

            span {
                font-size: 19px;
            }
        }
    }

    form {
        position: relative;
        width: 100%;
        top: 19.75%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid red;

        .emailInput {
            width: 66%;
            text-align: center;
            outline: none;
            padding: 12px;
            border-radius: 12px;
            border: 2px solid  rgb(23, 86, 157);
        }
    }

    .bottomText {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%; 
        top: 60%; 
        color: #7a39e0; 
        font-size: 20px;
        font-weight: 600; 
        margin: 0px auto;
        // border: 1px solid red;
    }

}



@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile
    .supportengine-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        // border: 1px solid green;
        width: 100px;
        height: 100px;
        // background-color: green;
        z-index: 1;
        position: fixed; 
        bottom: 14px;
        right: 0px
    }
}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile
    .supportengine-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        // border: 1px solid green;
        width: 100px;
        height: 100px;
        // background-color: green;
        z-index: 1;
        position: fixed; 
        bottom: 14px;
        right: 0px
    }
    
}

@media only screen and (min-width: 451px) and (max-width: 700px){ //Mini Tablet
    .supportengine-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        // border: 1px solid green;
        width: 100px;
        height: 100px;
        // background-color: green;
        z-index: 1;
        position: fixed; 
        bottom: 14px;
        right: 0px
    }
}

@media only screen and (min-width: 701px) and (max-width: 960px){ //Mini Tablet
    .supportengine-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        // border: 1px solid green;
        width: 100px;
        height: 100px;
        // background-color: green;
        z-index: 1;
        position: fixed; 
        bottom: 14px;
        right: 0px
    }
    
}