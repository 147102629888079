.admin-container {
    display: flex;
    flex-direction: column;


    .admin-wrapper {
        height: max-content;
        margin: 60px 0px 0px 0px;
        // border: 1px solid black;
        display: flex;
        flex-direction: row;

        .admin-sidenav {
            height: 100%;
            width: 60px;
            // border: 1px solid blue;
        }

        .admin-mobile {
            display: none;
        }

        .admin-body {
            min-height: 100vh;
            height: max-content;
            width: 100%;
            // border: 1px solid orange;
            background-color: #fafafa;

            .admin-body-header {
                display: flex;
                flex-direction: row;
                // align-items: center;
                justify-content: space-around;
                // height: 150px;
                height: 100px;
                width: 100%;
                margin: 0px 0px 0px 0px;
                // border: 1px solid orange;
                // background-color: #0f5bce;
                background-color: #0C4CAC;
                border-radius: 0px 0px 20px 20px;
                position: relative;

                .page-title {
                    display: flex;
                    position: absolute;
                    color: #fff;
                    margin: 10px 0px 0px 20px;
                    // border: 1px solid white;
                    left: 0px;
                    top: 0px;
                }

                .name-space {
                    display: flex;
                    position: absolute;
                    color: #fff;
                    margin: 10px 20px 0px 0px;
                    font-size: 14px;
                    top: 0px;
                    right: 0px;

                }

            }

            .admin-body-body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .admin-menu-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 10px;
                    gap: 10px;
                    // align-items: center;
                    // justify-content: center;
                    // margin-bottom: 300px;
                    margin: -30px 0px 150px 0px;
                    // border: 1px solid red;
                    width: 100%;
                    height: max-content;
                    z-index: 2;

                    .admin-item {
                        border: 1px solid #EEEEEE;
                        padding: 15px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 18px;
                        width: 100px;
                        height: 100px;
                        position: relative;
                        background-color: white;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .icon {
                            height: 40px;
                            color: #598bd6;
                            // opacity: 0.8;
                            // border: 1px solid red;
                        }

                        .description {
                            font-size: 12px;
                        }

                        // .left {
                        //     display: flex;
                        //     flex-direction: column;
                        //     gap: 15px;

                        //     .notification-tag {
                        //         // border: 1px solid #000;
                        //         width: max-content;
                        //         padding: 2px 10px;
                        //         border-radius: 20px;
                        //         font-size: 12px;
                        //         height: 20px;
                        //         display: flex;
                        //         align-items: center;
                        //         justify-content: center;
    
                        //     }
    
                        //     .notification-tag.appointment {
                        //         background-color: #00C851;
                        //         color: white;
                        //     }

                        // }

                        // .right {
                        //     display: flex;
                        //     // height: 100px;
                        //     width: max-content;
                        //     // border: 1px solid black;
                        //     align-items: center;

                        //     .notification-date {
                        //         display: flex;
                        //         align-items: center;
                        //         font-size: 12px;
                        //         gap: 10px;
                        //         color: #757575;
                        //     }
                        // }

                    }

                }

                .admin-menu-wrapper.active {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transition: 0.5s ease;
                }

                .admin-menu-wrapper.inactive {
                    opacity: 0;
                    // visibility: hidden;
                    display: none;
                    transform: translateY(-20px);
                    transition: 0.5s ease;
                    position: absolute;
                }

                .admin-menu-functions-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 100px 10px 20px 10px;
                    gap: 10px;
                    margin: -30px 0px 0px 0px;
                    // border: 1px solid red;
                    width: 100%;
                    height: max-content;
                    z-index: 2;
                    // border: 1px solid blue;

                    .admin-functions-item {
                        display: flex;
                        flex-direction: column;
                        width: 305px;
                        padding: 50px 40px;
                        background-color: white;
                        border: 1px solid #EEEEEE;
                        margin: 0px auto;
                        position: relative;

                        .back-button {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            z-index: 2;
                            margin: -50px 0px 0px -120px;
                            // border: 1px solid red;
                            padding: 10px;
                            border-radius: 50%;
                            cursor: pointer;
                            background-color: #6a7b90d2;
                            color: white;
                        }

                        .function-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            z-index: 2;
                            margin: -100px auto 40px auto;
                            // border: 1px solid red;
                            padding: 15px;
                            border-radius: 50%;
                            cursor: pointer;
                            background-color: #0C4CAC;
                            color: white;
                            width: 70px;
                            height: 70px;
                        }

                        .notification {
                            border:  1px solid red;
                            position: absolute;
                            margin: -170px auto 40px auto;
                            padding: 10px 20px;
                            width: max-content;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 20px;
                        }

                        .notification.success {
                            color: white;
                            background: #47ae74;
                            border: 1px solid #47ae74;
                        }

                        .notification.error {
                            color: white;
                            background: #b05150;
                            border: 1px solid #b05150;
                        }

                        form {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            // align-items: center;
                            justify-content: space-between;
                            // padding: 30px;
                            // gap: 20px;
                            // border: 1px solid red;
                            width: 100%;

                            section.group {
                                display: flex;
                                // flex-direction: row;
                                flex-direction: column;
                                // position: relative;
                                margin-bottom: 50px;
                                //border: 1px solid orange;
                                // width: 100%;
                                width: max-content;
                                gap: 10px;
                                flex-wrap: wrap;

                            }

                            section {
                                display: flex;
                                position: relative;
                                margin-bottom: 30px;
                                //border: 1px solid orange;
                                // width: 100%;
                                width: max-content;

                                label {
                                    position: absolute;
                                    margin: -20px 0px 10px 6px;
                                    font-size: 12px;
                                    width: max-content;
                                }

                                .formSelect {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    height: 40px;

                                    //Replace default arrow
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                    //background-position: right 10px top 50%;

                                }

                                .formInput {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    width: 260px;
                                }

                                .formTextArea {
                                    width: 100%;
                                    padding: 20px;
                                    border-radius: 2em;
                                    border: 1px solid #e0e0e0;
                                    // height: 100px;
                                }

                                input:focus, textarea:focus, select:focus {
                                    outline: #2196f3;
                                    border: 1px solid #2196f3;
                                }

                                .visibility_icon {
                                    // border: 1px solid red;
                                    width: max-content;
                                    position: absolute;
                                    margin: 5px 5px 0px 0px;
                                    right: 0;
                                    color: #5e5e5e;
                                }
                                
                                .required {
                                    border: 1px solid red;
                                }
                            }
                        }

                        .submit-button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color:#0C4CAC;
                            width: max-content;
                            height: 40px;
                            padding: 0px 40px;
                            border-radius: 9px;
                            margin: 0px auto;
                            color: white;
                            cursor: pointer;
                        }
                        
                    }

                    .admin-functions-item.active {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        transition: 0.5s ease;
                    }

                    .admin-functions-item.inactive {
                        opacity: 0;
                        // visibility: hidden;
                        display: none;
                        transform: translateY(-20px);
                        transition: 0.5s ease;
                        position: absolute;
                    }
                }

            }

        }


    }    
}