
.authotp-container {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .authotp-wrapper {
        // display: flex;
        border: 1px solid #cfcfcf;
        border-radius: 7px;
        width: 300px;
        height: max-content;
        padding: 40px;
        align-items: center;

        .logo-holder {
            // border: 1px solid black;
            width: max-content;
            margin: 0px auto;
            img {
                width: 40px;
                height: 40px;
            }
        }

        .otp-success {
            background-color: #b9f6ca;
            color: #00c853;
            padding: 10px;
            text-align: center;
        }

        .otp-error {
            // border: 1px solid red;
            background-color: #ffcdd2;
            color: red;
            padding: 10px;
            text-align: center;

        }

        .auth-description {
            margin: 10px 0px 0px 0px;
            font-size: 17px;

            span {
                font-size: 20px;
                font-weight: 400;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            p {color: #707070;}

            .auth-email {
                margin: 5px 0px 5px 0px;
                border: 1px solid #c5c5c5;
                padding: 4px 10px;
                border-radius: 20px;
            }
        }

        .otpHolder {
            width: 100%;
            margin: 20px auto 0px auto;
            display: flex;
            gap: 5px;
            // border: 1px solid #c5c5c5;
            align-items: center;
            justify-content: center;

            .otp-field {
                // width: 35px;
                width: max-content;
                height: 35px;
                text-align: center;
                font-size: 20px;
                border: 1px solid #c5c5c5;
            }
            .otp-field:focus-visible {
                outline: 1px solid #0C4CAC;
                border: none;
            }
        }

        .otp-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            // border: 1px solid #c5c5c5;
            gap: 10px;
            margin: 20px auto 0px auto;
            font-size: 12px;

            .otp-clear {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                height: 30px;
                width: 50px;
                background-color: #707070;
                color: white;
                border-radius: 3px;
                cursor: pointer;
            }

            .otp-verify {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                height: 50px;
                width: 100px;
                background-color: #0C4CAC;
                color: white;
                border-radius: 3px;
                cursor: pointer;
                border: none;
            }
        
            button[disabled] {
                background-color: #0C4CAC;
                opacity: 0.7;
                color: white;
                cursor: not-allowed;
            }

        }

        .otp-remark {
            font-size: 13px;
            text-align: center;

            .otp-resend {
                color: #0C4CAC;
                cursor: pointer;
            }
        }
    }
}