
.booking-container {
    display: flex;
    flex-direction: column;

    .booking-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        margin: 60px 0px 0px 0px;
        background-image: url("../../assets/images/alarm.jpg");
        position: relative;
        background-size: cover;
        background-position-y: -400px;

        .booking-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #052659;
            opacity: 0.9;
            z-index: 1;

        }

        .booking-header {
            height: 100%;
            width: 1000px;
            color: white;
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                z-index: 7;
                margin-top: -70px;
                // transform: translateY(-140px);
                // transition: transform 1000ms ease 1s;
                // top: 460px;
                animation-name: slideDownUp;
                animation-duration: 1.5s;

            }

            @keyframes slideDownUp {
                from {
                    transform: translateY(100px);
                    opacity: 0;
                }

                to {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }

        }
    }

    .booking-decision {
        width: 800px;
        // border: 1px solid red;
        border-radius: 40px;
        height: max-content;
        margin: -100px auto 0px auto;
        z-index: 2;
        background-color: white;
        // box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        padding: 40px;
        animation-name: slideDownUp2;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        // animation-delay: 0.5s;

        .decision-item {
            width: 33%;
            height: 300px;
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
            // border: 1px solid #ececec;
            padding: 0px 0px 20px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            border-radius: 10px;
            position: relative;
            // background-color: #37BC9B;
            // background-color: #48CFAD;
            overflow: hidden;
            cursor: pointer;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                height: 30px;
                width: 30px;
                top: 10px;
                left: 15px;
                z-index: 8;
                // color: #052659;
                color: white;
                // background-color: #48CFAD;
                padding: 15px;
                border-radius: 50%;
            }

            .icon.now {
                background-color: #37BC9B;
            }

            .icon.later {
                background-color: #F6BB42;
            }

            .icon.monitor {
                background-color: #D770AD;
            }

            .action {
                display: flex;
                align-items: end;
                // border: 1px solid red;
                height: 100px;
                width: 92%;
                padding: 50px 0px 40px 20px;
                margin-top: -130px ;
                position: absolute;
                color: white;
                font-weight: 500;
                font-size: 19px;
            }

            .action.now {
                background-color: #48CFAD;
            }

            .action.later {
                background-color: #ffce54;
            }

            .action.monitor {
                background-color: #EC87C0;
            }

            .action-button {
                position: absolute;
                // border: 1px solid black;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                top: 250px;
                left: 170px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }

            .action-button.now {
                background-color: #37BC9B;
            }

            .action-button.later {
                background-color: #F6BB42;
            }

            .action-button.monitor {
                background-color: #D770AD;
            }

            .button-now {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                font-size: 20px;
                font-weight: 500;
                border: 1px solid #c5c5c5;
                width: 100%;
                color: #0C4CAC;
                padding: 50px 0px;
                border-radius: 40px;
            }

            .button-later {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-size: 20px;
                font-weight: 500;
                // border: 1px solid #c5c5c5;
                width: 100%;
                background-color: #ffbb33;
                color: white;
                padding: 50px 0px;
                border-radius: 40px;
            }

            .description {
                // border: 1px solid #c5c5c5;
                width: 90%;
                color: #c5c5c5;
                display: flex;
                z-index: 5;
                margin-top: 140px;
                font-size: 13px;
            }
        }
    }
    
    @keyframes slideDownUp2 {
        from {
            transform: translateY(100px);
            // opacity: 0;
        }

        to {
            transform: translateY(0px);
            // opacity: 1;
        }
    }

    .booking-wrapper {

    }
}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .booking-container {
    
        .booking-banner {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 200px;
            margin: 60px 0px 0px 0px;
            background-image: url("../../assets/images/alarm.jpg");
            position: relative;
            background-size: cover;
            background-position-y: 0px;
    
            .booking-overlay {
                height: 100%;
                width: 100%;
    
            }
    
            .booking-header {
                height: 100%;
                width: 100%;
                font-size: 18px;
    
                p{
                    z-index: 7;
                    // transform: translateY(-140px);
                    // transition: transform 1000ms ease 1s;
                    // top: 460px;
                    animation-name: slideDownUp;
                    animation-duration: 1s;
    
                }
    
                @keyframes slideDownUp {
                    from {
                        transform: translateY(100px);
                        opacity: 0;
                    }
    
                    to {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
    
            }
        }

        .booking-decision {
            width: 290px;
            // border: 1px solid red;
            height: 400px;
            margin: -60px auto 100px auto;
            border-radius: 40px;
            height: max-content;
            // margin: -100px auto 0px auto;
            z-index: 2;
            background-color: white;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding: 40px 10px;
            animation-name: slideDownUp2;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            // animation-delay: 0.5s;

    
            .decision-item {
                width: 80%;
                // border: 1px solid #ececec;
                box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                padding: 0px 0px 20px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                border-radius: 20px;
                text-align: center;
                font-size: 13px;
    
                .button-now {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    font-size: 20px;
                    font-weight: 500;
                    // border: 1px solid #c5c5c5;
                    width: 100%;
                    background-color: #00C851;
                    color: white;
                    padding: 50px 0px;
                    border-radius: 40px;
                }
    
                .button-later {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    font-size: 20px;
                    font-weight: 500;
                    // border: 1px solid #c5c5c5;
                    width: 100%;
                    background-color: #ffbb33;;
                    color: white;
                    padding: 50px 0px;
                    border-radius: 40px;
                }
                .action-button {
                    position: absolute;
                    // border: 1px solid black;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    top: 250px;
                    left: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
        }
    
        @keyframes slideDownUp2 {
            from {
                transform: translateY(100px);
                // opacity: 0;
            }

            to {
                transform: translateY(0px);
                // opacity: 1;
            }
        }
    
        .booking-wrapper {
    
        }
    }    

}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .booking-container {
    
        .booking-banner {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 200px;
            margin: 60px 0px 0px 0px;
            background-image: url("../../assets/images/alarm.jpg");
            position: relative;
            background-size: cover;
            background-position-y: 0px;
    
            .booking-overlay {
                height: 100%;
                width: 100%;
    
            }
    
            .booking-header {
                height: 100%;
                width: 100%;
                font-size: 18px;
    
                p{
                    z-index: 7;
                    // transform: translateY(-140px);
                    // transition: transform 1000ms ease 1s;
                    // top: 460px;
                    animation-name: slideDownUp;
                    animation-duration: 1s;
    
                }
    
                @keyframes slideDownUp {
                    from {
                        transform: translateY(100px);
                        opacity: 0;
                    }
    
                    to {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
    
            }
        }

        .booking-decision {
            width: 290px;
            // border: 1px solid red;
            height: 400px;
            margin: -60px auto 100px auto;
            border-radius: 40px;
            height: max-content;
            // margin: -100px auto 0px auto;
            z-index: 2;
            background-color: white;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding: 40px 10px;
            animation-name: slideDownUp2;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            // animation-delay: 0.5s;

    
            .decision-item {
                width: 80%;
                // border: 1px solid #ececec;
                box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                padding: 0px 0px 20px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                border-radius: 20px;
                text-align: center;
                font-size: 13px;
    
                .button-now {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    font-size: 20px;
                    font-weight: 500;
                    // border: 1px solid #c5c5c5;
                    width: 100%;
                    background-color: #00C851;
                    color: white;
                    padding: 50px 0px;
                    border-radius: 40px;
                }
    
                .button-later {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    font-size: 20px;
                    font-weight: 500;
                    // border: 1px solid #c5c5c5;
                    width: 100%;
                    background-color: #ffbb33;;
                    color: white;
                    padding: 50px 0px;
                    border-radius: 40px;
                }
                .action-button {
                    position: absolute;
                    // border: 1px solid black;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    top: 250px;
                    left: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
        }
    
        @keyframes slideDownUp2 {
            from {
                transform: translateY(100px);
                // opacity: 0;
            }

            to {
                transform: translateY(0px);
                // opacity: 1;
            }
        }
    
        .booking-wrapper {
    
        }
    }    

}

@media only screen and (min-width: 451px) and (max-width: 800px){ //Mini Tablet
    .booking-container {

        .booking-decision {
            width: 100%;
            // border: 1px solid red;
            height: 400px;
            margin: -60px auto 100px auto;
            border-radius: 40px;
            height: max-content;
            // margin: -100px auto 0px auto;
            z-index: 2;
            background-color: white;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding: 20px 0px;
            animation-name: slideDownUp2;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            // animation-delay: 0.5s;


            .decision-item {
                    width: 30%;
                    // border: 1px solid red;
                    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                    padding: 0px 0px 20px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 30px;
                    border-radius: 20px;
                    text-align: center;
                    font-size: 13px;
        
                    .button-now {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 60px;
                        font-size: 20px;
                        font-weight: 500;
                        // border: 1px solid #c5c5c5;
                        width: 100%;
                        background-color: #00C851;
                        color: white;
                        padding: 50px 0px;
                        border-radius: 40px;
                    }
        
                    .button-later {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 60px;
                        font-size: 20px;
                        font-weight: 500;
                        // border: 1px solid #c5c5c5;
                        width: 100%;
                        background-color: #ffbb33;;
                        color: white;
                        padding: 50px 0px;
                        border-radius: 40px;
                    }
                    .action-button {
                        position: absolute;
                        // border: 1px solid black;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        top: 250px;
                        left: 170px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                    }

            }
        }
    }
}