.ptransact-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
}

.ptransact-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: #fff;
    padding: 50px;
}

.ptransact-container.inactive {
    display: none;
    background-color: aqua;
}



.pappointments-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    //opacity: 0;
    transition: opacity .2s ease;
    height: 120%;
    z-index: 20;
    margin: -90px 0px 0px 0px;
    

    .pappointments-item {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: max-content;
        background-color: #c5c5c5;
        align-items: center;
        padding: 30px 10px;
        gap: 20px;
        border-radius: 10px;

        .pappointments-item-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 350px;
            height: 80px;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px;

            .tariff {
                font-size: 35px;
                font-weight: bold;

                img {
                    width: 25px;
                }
            }
        }
        .pappointments-item-description {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 350px;
            min-height: 100px;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px;
            // height: max-content;

            .pappointments-item-description-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                // border: 1px solid black;
                min-height: 20px;

                .left {
                    display: flex;
                    align-items: center;
                    width: 47%;
                    //border: 1px solid #cfcfcf;
                    font-size: 13px;
                    color: #757575;
                }
                .right {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    width: 47%;
                    //border: 1px solid #cfcfcf;
                    font-size: 13px;
                    color: #757575;
                    font-weight: 500;
                    height: max-content;
                }

                .central {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    //border: 1px solid #cfcfcf;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
}

.pappointments-container.inactive {
    display: none;
}

.appointmentbillinfo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    //opacity: 0;
    transition: opacity .2s ease;
    height: 120%;
    z-index: 20;
    margin: -90px 0px 0px 0px;

    .appointmentbillinfo-item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        // border: 1px solid red;

        .appointmentbillinfo-panel {
            display: flex;
            position: absolute;
            background-color:#005EFF;
            height: 30px;
            width: 1000px;
            margin-top: 70px;
            border-radius: 20px 20px 0px 0px;
            justify-content: space-around;
            color: #fff;
            // padding: 0px 20px;

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 200px;
                width: 95%;
                // width: max-content;
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 10%;
                // border: 1px solid red;

                .closeicon {
                    cursor: pointer;
                }
            }
        }

        .appointmentbillinfo-wrapper {
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            // border: 1px solid orange;
            justify-content: space-around;
            width: 1000px;
            height: 550px;
            margin-top: 100px;
            background-color: #fff;
            // border: 1px solid red;
            gap: 10px;
            overflow-y: scroll;
            padding: 10px 0px;
            position: relative;

            .notification {
                border:  1px solid red;
                border-radius: 5px;
                position: absolute;
                //margin: -150px 50px 140px 0px;
                right: 40px;
                top: 120px;
                padding: 10px 20px;
                width: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }

            .notification.success {
                color: white;
                background: #47ae74;
                border: 1px solid #47ae74;
            }

            .notification.error {
                color: white;
                background: #b05150;
                border: 1px solid #b05150;
            }


            .appointmentbillinfo-item-header {
                display: flex;
                flex-direction: column;
                align-items: left;
                // width: 100%;
                height: 80px;
                background-color: #e1f5fe8c;
                color: #0c4cacc2;
                border-radius: 10px;
                padding: 10px;
                // color: green;
                // border: 1px solid orange;
                margin: 0px 30px;
                gap: 20px;
    
                .tariff {
                    font-size: 35px;
                    font-weight: bold;
    
                    img {
                        width: 25px;
                    }
                }
                .trans-date {
                    font-size: 13px;
                }

            }

            span {
                margin: 0px 30px;
            }

            .appointmentbillinfo-item-description {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 900px;
                height: max-content;
                background-color: #fff;
                border-radius: 10px;
                padding: 10px;
                margin: 0px 30px;
                // border: 1px solid blue;
                // height: 800px;

                .appointmentbillinfo-item-description-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    // border: 1px solid black;
                    min-height: 20px;
                    // gap: 30px;
    
                    .left {
                        display: flex;
                        align-items: center;
                        width: 43%;
                        //border: 1px solid #cfcfcf;
                        font-size: 14px;
                        color: #757575;
                    }
                    .right {
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        width: 57%;
                        //border: 1px solid #cfcfcf;
                        font-size: 14px;
                        color: #757575;
                        font-weight: 500;
                        height: max-content;

                        .formSelect {
                            font-size: 12px;
                            border-radius: 9px;
                            cursor: pointer;
                            color: #333333;
                            padding: 8px 12px;
                            cursor: pointer;
                            outline: #2196f3;
                            border: 1px solid #2196f3;
                            height: 40px;
                            width: 100px;

                            //Replace default arrow
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                            //background-position: right 10px top 50%;

                            option {
                                display: flex;
                                // width: 100px;
                                flex-direction: row;

                                .span {
                                    height: 100px;
                                    width: 100px;
                                    background-color: #005EFF;
                                }
                            }
                        }
                    }

                    .right.notes {
                        padding: 20px 20px;
                        border: 1px solid #75757527;
                        border-radius: 9px;
                    }
                }

            }

            .appointmentbillinfo-button-holder {
                display: flex;
                width: 100%;
                // border: 1px solid orange;
                align-items: center;
                // margin: 30px 0px 40px 0px;

                button {
                    padding: 10px;
                    width: 200px;
                    margin: 0px auto 0px auto;
                    cursor: pointer;
                    border: none;
                    background-color: #005EFF; //new color
                    color: white;
                    height: 50px;
                    font-size: 15px;
                    border-radius: 9px;
                }
            }

        }

    }


}

.appointmentbillinfo-container.inactive {
    display: none;
}


.patreginfo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    //opacity: 0;
    transition: opacity .2s ease;
    height: 120%;
    z-index: 20;
    margin: -90px 0px 0px 0px;
    

    .patreginfo-item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;

        .patient-reg-panel {
            display: flex;
            position: absolute;
            background-color:#005EFF;
            height: 30px;
            width: 1000px;
            margin-top: 70px;
            border-radius: 20px 20px 0px 0px;
            justify-content: space-around;
            color: #fff;
            // padding: 0px 20px;

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 200px;
                width: 95%;
                // width: max-content;
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 10%;
                // border: 1px solid red;

                .closeicon {
                    cursor: pointer;
                }
            }
        }

        .patient-reg-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // border: 1px solid orange;
            justify-content: space-around;
            width: 1000px;
            height: 600px;
            margin-top: 100px;
            background-color: #fff;
            // border: 1px solid red;
            overflow-y: scroll;

            .left-items {
                display: flex;
                flex-direction: column;
                width: 65%;
                //border: 1px solid orange;
                height: max-content;

                .body-item {
                    // width: 33.1%;
                    // border: 1px solid orange;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 20px;

                    .error_msg {
                        // border: 1px solid red;
                        margin: 20px auto 10px auto;
                        padding: 10px 20px;
                        border-radius: 10px;
                        width: max-content;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        background-color: red;
                        color: white;
                        font-size: 13px;
                    }

                    .header-section {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 10px;
                        padding: 30px;
                        margin-bottom: 10px;
                        //border: 1px solid orange;
                    }

                    .body-body {

                        form {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding: 30px;
                            gap: 20px;
                            //background-color: #fff;

                            section {
                                display: flex;
                                position: relative;
                                margin-bottom: 50px;
                                width: max-content;
                                // border: 1px solid orange;

                                label {
                                    position: absolute;
                                    margin: -20px 0px 10px 6px;
                                    font-size: 12px;
                                    width: max-content;
                                }

                                .formSelect {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    height: 40px;

                                    //Replace default arrow
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                    //background-position: right 10px top 50%;

                                }

                                .formInput {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    width: 260px;
                                }

                                .formTextArea {
                                    width: 700px;
                                    padding: 20px;
                                    border-radius: 2em;
                                    border: 1px solid #e0e0e0;
                                    height: 100px;
                                }

                                input:focus, textarea:focus, select:focus {
                                    outline: #2196f3;
                                    border: 1px solid #2196f3;
                                }

                                .required {
                                    border: 1px solid red;
                                }
                            }
                        }
                    }
                }
            }

            .right-items {
                display: flex;
                flex-direction: column;
                width: 30%;
                //border: 1px solid orange;
                height: 100vh;
                gap: 30px;

                .body-item {
                    // width: 33.1%;
                    //border: 1px solid orange;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 20px;

                    .header-section {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 10px;
                        padding: 30px;
                        margin-bottom: 10px;
                        //border: 1px solid orange;
                    }

                    .body-body {

                        form {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding: 30px;
                            gap: 20px;

                            section {
                                display: flex;
                                position: relative;
                                margin-bottom: 50px;
                                //border: 1px solid orange;
                                width: 100%;

                                label {
                                    position: absolute;
                                    margin: -20px 0px 10px 6px;
                                    font-size: 12px;
                                    width: max-content;
                                }

                                .formSelect {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    height: 40px;

                                    //Replace default arrow
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                    //background-position: right 10px top 50%;

                                }

                                .formInput {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    width: 100%;
                                }

                                .formTextArea {
                                    width: 100%;
                                    padding: 20px;
                                    border-radius: 2em;
                                    border: 1px solid #e0e0e0;
                                    // height: 100px;
                                }

                                input:focus, textarea:focus, select:focus {
                                    outline: #2196f3;
                                    border: 1px solid #2196f3;
                                }
                                
                                .required {
                                    border: 1px solid red;
                                }
                            }
                        }
                    }
                }
            }

            .patient-button-holder {
                display: flex;
                width: 100%;
                // border: 1px solid orange;
                align-items: center;
                margin: 30px 0px 40px 0px;

                button {
                    padding: 10px;
                    width: 200px;
                    margin: 0px auto 0px auto;
                    cursor: pointer;
                    border: none;
                    background-color: #005EFF; //new color
                    color: white;
                    height: 60px;
                    font-size: 15px;
                    border-radius: 9px;
                }
            }

        }
    }

}

.patreginfo-container.inactive {
    display: none;
}