.puprofile-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .puprofile-wrapper {
        height: max-content;
        margin: 60px 0px 0px 0px;
        // border: 1px solid black;
        display: flex;
        flex-direction: row;
        //position: relative;

        .puprofile-sidenav {
            height: 100%;
            width: 60px;
            // border: 1px solid blue;
        }

        .puprofile-mobile {
            display: none;
        }

        .puprofile-modal-container {
            // display: none;
            // display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .5);
            //opacity: 0;
            transition: opacity .2s ease;
            height: 106%;
            z-index: 20;
            margin: -60px 0px 0px 0px;

            .puprofile-modal-items {
                height: 550px;
                width: 500px;
                margin: -890px 0px 0px 0px;
                // border: 1px solid blue;

                .puprofile-modal-item {
                    //border: 1px solid orange;
                    min-height: 550px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background-color: white;

                    .modal-header {
                        //border: 1px solid orange;
                        text-align: center;
                        color: #5f5f5f;
                    }

                    .modal-icon {
                        display: flex;
                        width: 100%;
                        height: 200px;
                        // border: 1px solid orange;

                        img {
                            width: max-content;
                            height: 220px;
                            margin: 0px auto 0px auto;
                        }
                    }

                    .modal-description {
                        padding: 20px;
                        color: #5f5f5f;


                        form {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding: 30px;
                            gap: 7px;
                            //background-color: #fff;

                            section {
                                display: flex;
                                position: relative;
                                margin-bottom: 25px;
                                width: max-content;
                                // border: 1px solid orange;

                                label {
                                    position: absolute;
                                    margin: -20px 0px 10px 6px;
                                    font-size: 12px;
                                    width: max-content;
                                }

                                .formSelect {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 8px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    height: 40px;

                                    //Replace default arrow
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                    //background-position: right 10px top 50%;

                                }

                                .formInput {
                                    font-size: 12px;
                                    border-radius: 9px;
                                    cursor: pointer;
                                    color: #333333;
                                    padding: 12px 12px;
                                    cursor: pointer;
                                    border: 1px solid #e6e6e6;
                                    width: 260px;
                                }

                                .formTextArea {
                                    width: 700px;
                                    padding: 20px;
                                    border-radius: 2em;
                                    border: 1px solid #e0e0e0;
                                    height: 100px;
                                }

                                input:focus, textarea:focus, select:focus {
                                    outline: #2196f3;
                                    border: 1px solid #2196f3;
                                }

                                .formInput.md {
                                    width: 165px;
                                }
    
                                .formTextArea.sm {
                                    width: 350px;
                                }

                                .formTextArea.md {
                                    width: 365px;
                                }

                                .formInput.lg {
                                    width: 365px;
                                }

                                .formSelect.lg {
                                    width: 395px;
                                }
                            }
                        }
                    }

                    .modal-buttons {
                        position: absolute;
                        display: flex;
                        width: max-content;
                        //border: 1px solid orange;
                        bottom: 0;
                        right: 0;
                        height: 50px;
                        align-items: center;
                        justify-content: right;
                        gap: 20px;
                        padding: 0px 20px;
                        margin: 0px 30px 10px 0px;

                        button {
                            height: max-content;
                            width: max-content;
                            min-width: 100px;
                            padding: 10px 20px;
                            border-radius: 7px;
                            background-color: #005EFF;
                            color: white;
                            border: none;
                            font-size: 13px;
                            cursor: pointer;
                        }

                        button.passive {
                            background-color: #fafafa;
                            color: #acacac;
                        }
                    }

                }

                .puprofile-modal-item.active {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transition: 0.5s ease;
                }

                .puprofile-modal-item.inactive {
                    opacity: 0;
                    // visibility: hidden;
                    display: none;
                    transform: translateY(-20px);
                    transition: 0.5s ease;
                    position: absolute;
                }
            }
        }

        .puprofile-modal-container.active {
            display: flex;
            animation-name: modalSlideDownUp;
            animation-duration: 1.5s;

        }
        .puprofile-modal-container.inactive {
            display: none;
        }

        @keyframes modalSlideDownUp {
            from {
                transform: translateY(100px);
                opacity: 0;
            }

            to {
                transform: translateY(0px);
                opacity: 1;
            }
        }

        .puprofile-body {
            // height: 100vh;
            height: max-content;
            width: 100%;
            // border: 1px solid orange;
            background-color: #fafafa;

            .puprofile-body-header {
                display: flex;
                flex-direction: row;
                //border: 1px solid orange;
                height: 100px;
                background-color: white;
                margin: 30px 0px 20px 0px;

                .left-info {
                    width: 10%;
                    //border: 1px solid orange;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .pic-holder {
                        border-radius: 50%;
                        overflow: hidden;
                        //border: 1px solid orange;
                        height: 100px;
                        width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        img {
                            width: 80px;
                        }
                    }
                }

                .right-info {
                    width: 90%;
                    //border: 1px solid orange;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 30px;
                    font-weight: 300;
                }
            }

            .puprofile-body-body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // border: 1px solid orange;
                justify-content: space-around;

                .left-items {
                    display: flex;
                    flex-direction: column;
                    width: 65%;
                    //border: 1px solid orange;
                    height: max-content;

                    .body-item {
                        // width: 33.1%;
                        // border: 1px solid orange;
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        border-radius: 20px;
    
                        .header-section {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 10px;
                            padding: 30px;
                            margin-bottom: 10px;
                            //border: 1px solid orange;
                        }

                        .body-body {

                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 30px;
                                gap: 20px;
                                //background-color: #fff;

                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 50px;
                                    width: max-content;
                                    // border: 1px solid orange;

                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }

                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;

                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;

                                    }

                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 260px;
                                    }

                                    .formTextArea {
                                        width: 700px;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        height: 100px;
                                    }

                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
                                    .formInput.sm {
                                        width: 200px;
                                    }
                                    // .formTextArea.sm {
                                    //     width: 600px;
                                    // }
                                }
                            }
                        }
                    }
                }

                .right-items {
                    display: flex;
                    flex-direction: column;
                    width: 30%;
                    //border: 1px solid orange;
                    height: 100vh;
                    gap: 30px;

                    .body-item {
                        // width: 33.1%;
                        //border: 1px solid orange;
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        border-radius: 20px;
    
                        .header-section {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 10px;
                            padding: 30px;
                            margin-bottom: 10px;
                            //border: 1px solid orange;
                        }

                        .body-body {

                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 30px;
                                gap: 20px;

                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 50px;
                                    //border: 1px solid orange;
                                    width: 100%;

                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }

                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;

                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;

                                    }

                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 100%;
                                    }

                                    .formTextArea {
                                        width: 100%;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        // height: 100px;
                                    }

                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .puprofile-button-holder {
                display: flex;
                width: 100%;
                // border: 1px solid orange;
                align-items: center;
                margin: 30px 0px 40px 0px;

                button {
                    padding: 10px;
                    width: 200px;
                    margin: 0px auto 0px auto;
                    cursor: pointer;
                    border: none;
                    background-color: #005EFF; //new color
                    color: white;
                    height: 60px;
                    font-size: 15px;
                    border-radius: 9px;
                }
            }

        }


    }

}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .puprofile-container {
        display: flex;
        flex-direction: column;
        position: relative;
    
        .puprofile-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
            //position: relative;
    
            .puprofile-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .puprofile-mobile {
                display: block;
            }
    
            .puprofile-modal-container {
                // display: none;
                // display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .5);
                //opacity: 0;
                transition: opacity .2s ease;
                height: 105%;
                z-index: 20;
                margin: -60px 0px 0px 0px;
    
                .puprofile-modal-items {
                    height: 550px;
                    width: 400px;
                    margin: -1290px 0px 0px 0px;
                    // border: 1px solid blue;
    
                    .puprofile-modal-item {
                        //border: 1px solid orange;
                        min-height: 500px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background-color: white;
    
                        .modal-header {
                            //border: 1px solid orange;
                            text-align: center;
                            color: #5f5f5f;
                            font-size: 12px;
                        }
    
                        .modal-icon {
                            display: flex;
                            width: 100%;
                            height: 200px;
                            // border: 1px solid orange;
    
                            img {
                                width: max-content;
                                height: 220px;
                                margin: 0px auto 0px auto;
                            }
                        }
    
                        .modal-description {
                            padding: 20px;
                            color: #5f5f5f;
                            font-size: 12px;
    
    
                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 15px;
                                gap: 7px;
                                //background-color: #fff;
    
                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 35px;
                                    width: max-content;
                                    // border: 1px solid orange;
    
                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }
    
                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;
    
                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;
    
                                    }
    
                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 12px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 170px;
                                    }
    
                                    .formTextArea {
                                        width: 280px;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        height: 100px;
                                    }
    
                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
    
                                    .formInput.sm {
                                        width: 165px;
                                    }
    
                                    .formInput.md {
                                        width: 280px;
                                    }
    
                                    .formTextArea.sm {
                                        width: 280px;
                                    }
    
                                    .formTextArea.md {
                                        width: 365px;
                                    }
    
                                    .formInput.lg {
                                        width: 365px;
                                    }
    
                                    .formSelect.sm {
                                        width: 100px;
                                    }
    
                                    .formSelect.md {
                                        width: 305px;
                                    }
    
                                    .formSelect.lg {
                                        width: 395px;
                                    }
                                }
                            }
                        }
    
                        .modal-buttons {
                            position: absolute;
                            display: flex;
                            width: max-content;
                            //border: 1px solid orange;
                            bottom: 0;
                            right: 0;
                            height: 50px;
                            align-items: center;
                            justify-content: right;
                            gap: 20px;
                            padding: 0px 20px;
                            margin: 0px 30px 10px 0px;
    
                            button {
                                height: max-content;
                                width: max-content;
                                min-width: 100px;
                                padding: 10px 20px;
                                border-radius: 7px;
                                background-color: #005EFF;
                                color: white;
                                border: none;
                                font-size: 13px;
                                cursor: pointer;
                            }
    
                            button.passive {
                                background-color: #fafafa;
                                color: #acacac;
                            }
                        }
    
                    }
    
                    .puprofile-modal-item.active {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        transition: 0.5s ease;
                    }
    
                    .puprofile-modal-item.inactive {
                        opacity: 0;
                        // visibility: hidden;
                        display: none;
                        transform: translateY(-20px);
                        transition: 0.5s ease;
                        position: absolute;
                    }
                }
            }
    
            .puprofile-modal-container.active {
                display: flex;
                animation-name: modalSlideDownUp;
                animation-duration: 1.5s;
    
            }
            .puprofile-modal-container.inactive {
                display: none;
            }
    
            @keyframes modalSlideDownUp {
                from {
                    transform: translateY(100px);
                    opacity: 0;
                }
    
                to {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
    
            .puprofile-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .puprofile-body-header {
                    display: flex;
                    flex-direction: row;
                    // border: 1px solid orange;
                    height: 100px;
                    background-color: white;
                    margin: 30px 0px 20px 0px;
    
                    .left-info {
                        width: 30%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        .pic-holder {
                            border-radius: 50%;
                            overflow: hidden;
                            // border: 1px solid orange;
                            height: 70px;
                            width: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            img {
                                width: 80px;
                            }
                        }
                    }
    
                    .right-info {
                        width: 70%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        font-weight: 300;
                    }
                }
    
                .puprofile-body-body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
                    justify-content: space-around;
    
                    .left-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        //border: 1px solid orange;
                        height: max-content;
    
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid orange;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 15px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 15px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .right-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        // border: 1px solid orange;
                        // height: 100vh;
                        gap: 30px;
   
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid blue;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 15px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 15px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .puprofile-button-holder {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;
                    align-items: center;
                    margin: -220px 0px 40px 0px;
    
                    button {
                        padding: 10px;
                        width: 200px;
                        margin: 0px auto 0px auto;
                        cursor: pointer;
                        border: none;
                        background-color: #005EFF; //new color
                        color: white;
                        height: 60px;
                        font-size: 15px;
                        border-radius: 9px;
                    }
                }
    
            }
    
    
        }
    
    }


}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .puprofile-container {
        display: flex;
        flex-direction: column;
        position: relative;
    
        .puprofile-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
            //position: relative;
    
            .puprofile-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .puprofile-mobile {
                display: block;
            }
    
            .puprofile-modal-container {
                // display: none;
                // display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .5);
                //opacity: 0;
                transition: opacity .2s ease;
                height: 105%;
                z-index: 20;
                margin: -60px 0px 0px 0px;
    
                .puprofile-modal-items {
                    height: 550px;
                    width: 400px;
                    margin: -1290px 0px 0px 0px;
                    // border: 1px solid blue;
    
                    .puprofile-modal-item {
                        //border: 1px solid orange;
                        min-height: 500px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background-color: white;
    
                        .modal-header {
                            //border: 1px solid orange;
                            text-align: center;
                            color: #5f5f5f;
                            font-size: 12px;
                        }
    
                        .modal-icon {
                            display: flex;
                            width: 100%;
                            height: 200px;
                            // border: 1px solid orange;
    
                            img {
                                width: max-content;
                                height: 220px;
                                margin: 0px auto 0px auto;
                            }
                        }
    
                        .modal-description {
                            padding: 20px;
                            color: #5f5f5f;
                            font-size: 12px;
    
    
                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 15px;
                                gap: 7px;
                                //background-color: #fff;
    
                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 35px;
                                    width: max-content;
                                    // border: 1px solid orange;
    
                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }
    
                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;
    
                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;
    
                                    }
    
                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 12px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 170px;
                                    }
    
                                    .formTextArea {
                                        width: 280px;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        height: 100px;
                                    }
    
                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
    
                                    .formInput.sm {
                                        width: 165px;
                                    }
    
                                    .formInput.md {
                                        width: 280px;
                                    }
    
                                    .formTextArea.sm {
                                        width: 280px;
                                    }
    
                                    .formTextArea.md {
                                        width: 365px;
                                    }
    
                                    .formInput.lg {
                                        width: 365px;
                                    }
    
                                    .formSelect.sm {
                                        width: 100px;
                                    }
    
                                    .formSelect.md {
                                        width: 305px;
                                    }
    
                                    .formSelect.lg {
                                        width: 395px;
                                    }
                                }
                            }
                        }
    
                        .modal-buttons {
                            position: absolute;
                            display: flex;
                            width: max-content;
                            //border: 1px solid orange;
                            bottom: 0;
                            right: 0;
                            height: 50px;
                            align-items: center;
                            justify-content: right;
                            gap: 20px;
                            padding: 0px 20px;
                            margin: 0px 30px 10px 0px;
    
                            button {
                                height: max-content;
                                width: max-content;
                                min-width: 100px;
                                padding: 10px 20px;
                                border-radius: 7px;
                                background-color: #005EFF;
                                color: white;
                                border: none;
                                font-size: 13px;
                                cursor: pointer;
                            }
    
                            button.passive {
                                background-color: #fafafa;
                                color: #acacac;
                            }
                        }
    
                    }
    
                    .puprofile-modal-item.active {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        transition: 0.5s ease;
                    }
    
                    .puprofile-modal-item.inactive {
                        opacity: 0;
                        // visibility: hidden;
                        display: none;
                        transform: translateY(-20px);
                        transition: 0.5s ease;
                        position: absolute;
                    }
                }
            }
    
            .puprofile-modal-container.active {
                display: flex;
                animation-name: modalSlideDownUp;
                animation-duration: 1.5s;
    
            }
            .puprofile-modal-container.inactive {
                display: none;
            }
    
            @keyframes modalSlideDownUp {
                from {
                    transform: translateY(100px);
                    opacity: 0;
                }
    
                to {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
    
            .puprofile-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .puprofile-body-header {
                    display: flex;
                    flex-direction: row;
                    // border: 1px solid orange;
                    height: 100px;
                    background-color: white;
                    margin: 30px 0px 20px 0px;
    
                    .left-info {
                        width: 30%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        .pic-holder {
                            border-radius: 50%;
                            overflow: hidden;
                            // border: 1px solid orange;
                            height: 70px;
                            width: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            img {
                                width: 80px;
                            }
                        }
                    }
    
                    .right-info {
                        width: 70%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        font-weight: 300;
                    }
                }
    
                .puprofile-body-body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
                    justify-content: space-around;
    
                    .left-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        //border: 1px solid orange;
                        height: max-content;
    
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid orange;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 25px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 25px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .right-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        // border: 1px solid orange;
                        // height: 100vh;
                        gap: 30px;
   
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid blue;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 25px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 25px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .puprofile-button-holder {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;
                    align-items: center;
                    margin: -220px 0px 40px 0px;
    
                    button {
                        padding: 10px;
                        width: 200px;
                        margin: 0px auto 0px auto;
                        cursor: pointer;
                        border: none;
                        background-color: #005EFF; //new color
                        color: white;
                        height: 60px;
                        font-size: 15px;
                        border-radius: 9px;
                    }
                }
    
            }
    
    
        }
    
    }


}

@media only screen and (min-width: 451px) and (max-width: 800px){ //Mini Tablet

    .puprofile-container {
        display: flex;
        flex-direction: column;
        position: relative;
    
        .puprofile-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
            //position: relative;
    
            .puprofile-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .puprofile-mobile {
                display: block;
            }
    
            .puprofile-modal-container {
                // display: none;
                // display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .5);
                //opacity: 0;
                transition: opacity .2s ease;
                height: 105%;
                z-index: 20;
                margin: -60px 0px 0px 0px;
    
                .puprofile-modal-items {
                    height: 550px;
                    width: 400px;
                    margin: -1290px 0px 0px 0px;
                    // border: 1px solid blue;
    
                    .puprofile-modal-item {
                        //border: 1px solid orange;
                        min-height: 500px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background-color: white;
    
                        .modal-header {
                            //border: 1px solid orange;
                            text-align: center;
                            color: #5f5f5f;
                            font-size: 12px;
                        }
    
                        .modal-icon {
                            display: flex;
                            width: 100%;
                            height: 200px;
                            // border: 1px solid orange;
    
                            img {
                                width: max-content;
                                height: 220px;
                                margin: 0px auto 0px auto;
                            }
                        }
    
                        .modal-description {
                            padding: 20px;
                            color: #5f5f5f;
                            font-size: 12px;
    
    
                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 15px;
                                gap: 7px;
                                //background-color: #fff;
    
                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 35px;
                                    width: max-content;
                                    // border: 1px solid orange;
    
                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }
    
                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;
    
                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;
    
                                    }
    
                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 12px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 170px;
                                    }
    
                                    .formTextArea {
                                        width: 280px;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        height: 100px;
                                    }
    
                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
    
                                    .formInput.sm {
                                        width: 165px;
                                    }
    
                                    .formInput.md {
                                        width: 280px;
                                    }
    
                                    .formTextArea.sm {
                                        width: 280px;
                                    }
    
                                    .formTextArea.md {
                                        width: 365px;
                                    }
    
                                    .formInput.lg {
                                        width: 365px;
                                    }
    
                                    .formSelect.sm {
                                        width: 100px;
                                    }
    
                                    .formSelect.md {
                                        width: 305px;
                                    }
    
                                    .formSelect.lg {
                                        width: 395px;
                                    }
                                }
                            }
                        }
    
                        .modal-buttons {
                            position: absolute;
                            display: flex;
                            width: max-content;
                            //border: 1px solid orange;
                            bottom: 0;
                            right: 0;
                            height: 50px;
                            align-items: center;
                            justify-content: right;
                            gap: 20px;
                            padding: 0px 20px;
                            margin: 0px 30px 10px 0px;
    
                            button {
                                height: max-content;
                                width: max-content;
                                min-width: 100px;
                                padding: 10px 20px;
                                border-radius: 7px;
                                background-color: #005EFF;
                                color: white;
                                border: none;
                                font-size: 13px;
                                cursor: pointer;
                            }
    
                            button.passive {
                                background-color: #fafafa;
                                color: #acacac;
                            }
                        }
    
                    }
    
                    .puprofile-modal-item.active {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        transition: 0.5s ease;
                    }
    
                    .puprofile-modal-item.inactive {
                        opacity: 0;
                        // visibility: hidden;
                        display: none;
                        transform: translateY(-20px);
                        transition: 0.5s ease;
                        position: absolute;
                    }
                }
            }
    
            .puprofile-modal-container.active {
                display: flex;
                animation-name: modalSlideDownUp;
                animation-duration: 1.5s;
    
            }
            .puprofile-modal-container.inactive {
                display: none;
            }
    
            @keyframes modalSlideDownUp {
                from {
                    transform: translateY(100px);
                    opacity: 0;
                }
    
                to {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
    
            .puprofile-body {
                // height: 100vh;
                height: max-content;
                // height: 500px;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .puprofile-body-header {
                    display: flex;
                    flex-direction: row;
                    // border: 1px solid orange;
                    height: 100px;
                    background-color: white;
                    margin: 30px 0px 20px 0px;
    
                    .left-info {
                        width: 30%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        .pic-holder {
                            border-radius: 50%;
                            overflow: hidden;
                            // border: 1px solid orange;
                            height: 70px;
                            width: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            img {
                                width: 80px;
                            }
                        }
                    }
    
                    .right-info {
                        width: 70%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        font-weight: 300;
                    }
                }
    
                .puprofile-body-body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
                    justify-content: space-around;
    
                    .left-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        //border: 1px solid orange;
                        height: max-content;
    
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid orange;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 35px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 35px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        //  border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
    
                                        .formInput.sm {
                                            width: 200px;
                                        }
                                        .formTextArea.sm {
                                            width: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .right-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        // border: 1px solid orange;
                        // height: 100vh;
                        gap: 30px;
   
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid blue;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 25px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 25px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                        .formInput.sm {
                                            width: 200px;
                                        }
                                        .formTextArea.sm {
                                            width: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .puprofile-button-holder {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;
                    align-items: center;
                    margin: -350px 0px 40px 0px;
    
                    button {
                        padding: 10px;
                        width: 200px;
                        margin: 0px auto 0px auto;
                        cursor: pointer;
                        border: none;
                        background-color: #005EFF; //new color
                        color: white;
                        height: 60px;
                        font-size: 15px;
                        border-radius: 9px;
                    }
                }
    
            }
    
    
        }
    
    }
}

@media only screen and (min-width: 801px) and (max-width: 960px){ //Mini Tablet

    .puprofile-container {
        display: flex;
        flex-direction: column;
        position: relative;
    
        .puprofile-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
            //position: relative;
    
            .puprofile-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }
    
            .puprofile-mobile {
                display: block;
            }
    
            .puprofile-modal-container {
                // display: none;
                // display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .5);
                //opacity: 0;
                transition: opacity .2s ease;
                height: 105%;
                z-index: 20;
                margin: -60px 0px 0px 0px;
    
                .puprofile-modal-items {
                    height: 550px;
                    width: 400px;
                    margin: -1290px 0px 0px 0px;
                    // border: 1px solid blue;
    
                    .puprofile-modal-item {
                        //border: 1px solid orange;
                        min-height: 500px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background-color: white;
    
                        .modal-header {
                            //border: 1px solid orange;
                            text-align: center;
                            color: #5f5f5f;
                            font-size: 12px;
                        }
    
                        .modal-icon {
                            display: flex;
                            width: 100%;
                            height: 200px;
                            // border: 1px solid orange;
    
                            img {
                                width: max-content;
                                height: 220px;
                                margin: 0px auto 0px auto;
                            }
                        }
    
                        .modal-description {
                            padding: 20px;
                            color: #5f5f5f;
                            font-size: 12px;
    
    
                            form {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                padding: 15px;
                                gap: 7px;
                                //background-color: #fff;
    
                                section {
                                    display: flex;
                                    position: relative;
                                    margin-bottom: 35px;
                                    width: max-content;
                                    // border: 1px solid orange;
    
                                    label {
                                        position: absolute;
                                        margin: -20px 0px 10px 6px;
                                        font-size: 12px;
                                        width: max-content;
                                    }
    
                                    .formSelect {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 8px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        height: 40px;
    
                                        //Replace default arrow
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                        //background-position: right 10px top 50%;
    
                                    }
    
                                    .formInput {
                                        font-size: 12px;
                                        border-radius: 9px;
                                        cursor: pointer;
                                        color: #333333;
                                        padding: 12px 12px;
                                        cursor: pointer;
                                        border: 1px solid #e6e6e6;
                                        width: 170px;
                                    }
    
                                    .formTextArea {
                                        width: 280px;
                                        padding: 20px;
                                        border-radius: 2em;
                                        border: 1px solid #e0e0e0;
                                        height: 100px;
                                    }
    
                                    input:focus, textarea:focus, select:focus {
                                        outline: #2196f3;
                                        border: 1px solid #2196f3;
                                    }
    
                                    .formInput.sm {
                                        width: 165px;
                                    }
    
                                    .formInput.md {
                                        width: 280px;
                                    }
    
                                    .formTextArea.sm {
                                        width: 280px;
                                    }
    
                                    .formTextArea.md {
                                        width: 365px;
                                    }
    
                                    .formInput.lg {
                                        width: 365px;
                                    }
    
                                    .formSelect.sm {
                                        width: 100px;
                                    }
    
                                    .formSelect.md {
                                        width: 305px;
                                    }
    
                                    .formSelect.lg {
                                        width: 395px;
                                    }
                                }
                            }
                        }
    
                        .modal-buttons {
                            position: absolute;
                            display: flex;
                            width: max-content;
                            //border: 1px solid orange;
                            bottom: 0;
                            right: 0;
                            height: 50px;
                            align-items: center;
                            justify-content: right;
                            gap: 20px;
                            padding: 0px 20px;
                            margin: 0px 30px 10px 0px;
    
                            button {
                                height: max-content;
                                width: max-content;
                                min-width: 100px;
                                padding: 10px 20px;
                                border-radius: 7px;
                                background-color: #005EFF;
                                color: white;
                                border: none;
                                font-size: 13px;
                                cursor: pointer;
                            }
    
                            button.passive {
                                background-color: #fafafa;
                                color: #acacac;
                            }
                        }
    
                    }
    
                    .puprofile-modal-item.active {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        transition: 0.5s ease;
                    }
    
                    .puprofile-modal-item.inactive {
                        opacity: 0;
                        // visibility: hidden;
                        display: none;
                        transform: translateY(-20px);
                        transition: 0.5s ease;
                        position: absolute;
                    }
                }
            }
    
            .puprofile-modal-container.active {
                display: flex;
                animation-name: modalSlideDownUp;
                animation-duration: 1.5s;
    
            }
            .puprofile-modal-container.inactive {
                display: none;
            }
    
            @keyframes modalSlideDownUp {
                from {
                    transform: translateY(100px);
                    opacity: 0;
                }
    
                to {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
    
            .puprofile-body {
                // height: 100vh;
                height: max-content;
                // height: 500px;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .puprofile-body-header {
                    display: flex;
                    flex-direction: row;
                    // border: 1px solid orange;
                    height: 100px;
                    background-color: white;
                    margin: 30px 0px 20px 0px;
    
                    .left-info {
                        width: 30%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        .pic-holder {
                            border-radius: 50%;
                            overflow: hidden;
                            // border: 1px solid orange;
                            height: 70px;
                            width: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            img {
                                width: 80px;
                            }
                        }
                    }
    
                    .right-info {
                        width: 70%;
                        //border: 1px solid orange;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        font-weight: 300;
                    }
                }
    
                .puprofile-body-body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
                    justify-content: space-around;
    
                    .left-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        //border: 1px solid orange;
                        height: max-content;
    
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid orange;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 35px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 35px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        //  border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
    
                                        .formInput.sm {
                                            width: 200px;
                                        }
                                        .formTextArea.sm {
                                            width: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .right-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        // border: 1px solid orange;
                        // height: 100vh;
                        gap: 30px;
   
                        .body-item {
                            // width: 33.1%;
                            // border: 1px solid blue;
                            display: flex;
                            flex-direction: column;
                            background-color: white;
                            border-radius: 20px;
        
                            .header-section {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                gap: 10px;
                                padding: 20px 25px;
                                margin-bottom: 10px;
                                // border: 1px solid orange;
                            }
    
                            .body-body {
    
                                form {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 25px;
                                    gap: 20px;
                                    //background-color: #fff;
    
                                    section {
                                        display: flex;
                                        position: relative;
                                        margin-bottom: 20px;
                                        width: max-content;
                                        // border: 1px solid orange;
    
                                        label {
                                            position: absolute;
                                            margin: -20px 0px 10px 6px;
                                            font-size: 12px;
                                            width: max-content;
                                        }
    
                                        .formSelect {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            height: 40px;
                                            width: 100px;
    
                                            //Replace default arrow
                                            appearance: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            //background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www/w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23666666;" /></svg>');
                                            //background-position: right 10px top 50%;
    
                                        }
    
                                        .formInput {
                                            font-size: 12px;
                                            border-radius: 9px;
                                            cursor: pointer;
                                            color: #333333;
                                            padding: 8px 12px;
                                            cursor: pointer;
                                            border: 1px solid #e6e6e6;
                                            width: 170px;
                                        }
    
                                        .formTextArea {
                                            width: 280px;
                                            padding: 20px;
                                            border-radius: 2em;
                                            border: 1px solid #e0e0e0;
                                            height: 100px;
                                        }
    
                                        input:focus, textarea:focus, select:focus {
                                            outline: #2196f3;
                                            border: 1px solid #2196f3;
                                        }
                                        .formInput.sm {
                                            width: 200px;
                                        }
                                        .formTextArea.sm {
                                            width: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .puprofile-button-holder {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;
                    align-items: center;
                    margin: -350px 0px 40px 0px;
    
                    button {
                        padding: 10px;
                        width: 200px;
                        margin: 0px auto 0px auto;
                        cursor: pointer;
                        border: none;
                        background-color: #005EFF; //new color
                        color: white;
                        height: 60px;
                        font-size: 15px;
                        border-radius: 9px;
                    }
                }
    
            }
    
    
        }
    
    }
}