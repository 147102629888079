
.footer-container {

    .footer-top {
        background-color: #2E2E2E;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .footer-top-upper {
            width: 400px;
            height: 50px;
            background-color: #0C4CAC;
            position: absolute;
            margin: -160px 0px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: white;

            .footer-top-upper-left {
                width: 50%;
            }

            .footer-top-upper-right {
                width: 50%;
            }
        }

        .footer-top-inner {
            width: 600px;
            display: flex;
            // border: 1px solid white;
            align-items: center;
            justify-content: center;
            // flex-direction: row;
            // gap: 30px;

            .footer-logo-holder {

            }

            .footer-link-holder {
                display: flex;
                gap: 20px;
                justify-content: space-around;
                align-items: center;
                color: white;
                font-size: 11px;
                // border: 1px solid white;

                div {
                    cursor: pointer;
                }

                // div:nth-child(5) {
                //     background-color: #0C4CAC;
                //     color: #fff;
                //     padding: 5px 10px;
                //     font-weight: 500;
                //     border-radius: 19px;
                // }

                .link {
                    text-decoration: none;
                    color: #fff;

                    div {
                        cursor: pointer;
                    }
                }

                .link.mysapphire {
                    background-color: #0C4CAC;
                    color: #fff;
                    padding: 5px 10px;
                    font-weight: 500;
                    border-radius: 19px;
                }
            }
        }
    }

    .footer-middle {
        background-color: #E0E0E0;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
    }
}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile
 

}