.receipt-skeleton-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    .receipt-skeleton {
        width: 270px;
        gap: 10px;
        border: 1px solid #EEEEEE;
        display: flex;
        flex-direction: column;
        padding: 15px;
        border-radius: 5px;
        z-index: 0;

        .receipt-skeleton-title {
            width: 50%;
        }

        .receipt-skeleton-tags {
            display: flex;
            flex-direction: column;
        }
    }
}
@media screen and (max-width: 480px) {
    .receipt-skeleton-wrapper {

        .receipt-skeleton {
            width: 100%;
        }

    }

}


.climbingBox-wrapper {
    display: flex;
    width: 100%;
    height: 75vh;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;

}