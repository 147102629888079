.services-container {
    background-image: url("../../assets/images/gray-background.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    // background-position-x: 30px;

    .services-wrapper {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 1000px;
        // border: 1px solid red;

        .services-header {
            display: flex;
            flex-direction: column;
            position: relative;

            .spikes {

                display: flex;
                flex-direction: column;
                position: absolute;
                gap: 9px;
                transform: rotate(35.277deg);
                margin: -1px 0px 0px 2px;

                .spike {
                    height: 2px;
                    width: 19.416px;
                    background-color: #69F0AE;
                }

                .spikeOne {
                    transform: rotate(29.277deg);
                    width: 13px;
                }
                .spikeTwo {
                    margin-left: -10px;
                }
                .spikeThree {
                    transform: rotate(-29.277deg);
                    width: 13px;
                }
            }

            .services-header-text {
                margin: 5px 20px;
                color: #838383;
                font-size: 25px;
                font-weight: 600;

            }

        }

        .services-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 50px;
            max-width: 1000px;
            // border: 1px solid black;
            // align-items: center;
            justify-content: center;
            margin: 0px auto;

            .services-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 10px;
                border: 1px solid #f5f5f5;
                width: 150px;
                background-color: #fff;

                .img-holder{
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    background-color: rgba(12, 76, 172, 0.10);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 40px;
                        height: 40px;
                    }

                }

                p{
                    // width: 120px;
                    font-size: 13px;
                    text-align: center;
                }
            }
        }
    }
}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .services-container {

        .services-wrapper {
            padding: 40px 20px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            // width: 1000px;

            .services-header {

                .spikes {
    
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    gap: 9px;
                    transform: rotate(35.277deg);
                    margin: -3px 0px 0px 2px;
    
                    .spike {
                        height: 2px;
                        width: 19.416px;
                        background-color: #69F0AE;
                    }
    
                    .spikeOne {
                        transform: rotate(29.277deg);
                        width: 13px;
                    }
                    .spikeTwo {
                        margin-left: -10px;
                    }
                    .spikeThree {
                        transform: rotate(-29.277deg);
                        width: 13px;
                    }
                }

                .services-header-text {
                    font-size: 17px;
                }
            }

            .services-items {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;
                max-width: 1000px;
                // border: 1px solid black;
                // align-items: center;
                justify-content: center;
                margin: 0px auto;

                .services-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px 10px;
                    border: 1px solid #f5f5f5;
                    width: 100px;
                    background-color: #fff;
    
                    .img-holder{
                        border-radius: 50%;
                        width: 70px;
                        height: 70px;
                        background-color: rgba(12, 76, 172, 0.10);
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        img {
                            width: 30px;
                            height: 30px;
                        }
    
                    }
    
                    p{
                        // width: 120px;
                        font-size: 11px;
                        text-align: center;
                    }

                }
            }
        }
    }
}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .services-container {

        .services-wrapper {
            padding: 40px 20px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            // width: 1000px;

            .services-header {

                .spikes {
    
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    gap: 9px;
                    transform: rotate(35.277deg);
                    margin: -3px 0px 0px 2px;
    
                    .spike {
                        height: 2px;
                        width: 19.416px;
                        background-color: #69F0AE;
                    }
    
                    .spikeOne {
                        transform: rotate(29.277deg);
                        width: 13px;
                    }
                    .spikeTwo {
                        margin-left: -10px;
                    }
                    .spikeThree {
                        transform: rotate(-29.277deg);
                        width: 13px;
                    }
                }

                .services-header-text {
                    font-size: 17px;
                }
            }

            .services-items {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;
                max-width: 1000px;
                // border: 1px solid black;
                // align-items: center;
                justify-content: center;
                margin: 0px auto;

                .services-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px 10px;
                    border: 1px solid #f5f5f5;
                    width: 130px;
                    background-color: #fff;
    
                    .img-holder{
                        border-radius: 50%;
                        width: 70px;
                        height: 70px;
                        background-color: rgba(12, 76, 172, 0.10);
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        img {
                            width: 30px;
                            height: 30px;
                        }
    
                    }
    
                    p{
                        // width: 120px;
                        font-size: 11px;
                        text-align: center;
                    }

                }
            }
        }
    }
}