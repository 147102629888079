.pportal-container {
    display: flex;
    flex-direction: column;

    .pportal-wrapper {
        height: max-content;
        margin: 60px 0px 0px 0px;
        // border: 1px solid black;
        display: flex;
        flex-direction: row;

        .pportal-sidenav {
            height: 100%;
            width: 60px;
            // border: 1px solid blue;
        }

        .pportal-mobile {
            display: none;
        }

        .pportal-body {
            // height: 100vh;
            height: max-content;
            width: 100%;
            // border: 1px solid orange;
            background-color: #fafafa;

            .pportal-body-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                height: 100px;
                width: 100%;
                margin: 0px 0px 0px 0px;
                // border: 1px solid orange;

                .name-space {
                    color: #0C4CAC;
                    font-size: 20px;

                }
                .search-bar {
                    position: relative;
                    // position: fixed;
                    // z-index: 10;

                    input:focus, textarea:focus, select:focus {
                        outline: #0C4CAC;
                        border: 1px solid #0C4CAC;
                    }

                    .search-icon {
                        position: absolute;
                        margin: 12px 0px 0px 12px;
                        color: #e0e0e0;
                    }
                    .formInput {
                        width: 220px;
                        padding: 15px 10px 15px 45px;
                        border-radius: 2em;
                        border: 1px solid #ececec;


                    }
                }
                .sorting {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    // align-items: center;
                    // justify-content: center;
                    // border: 1px solid blue;

                    .sorting-grid {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        width: 40px;
                        // border: 1px solid blue;
                        border-radius: 50%;
                        background-color: white;
                        color: #0C4CAC;
                        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                        cursor: pointer;
                    }
                    .sorting-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        width: 40px;
                        // border: 1px solid blue;
                        border-radius: 50%;
                        // background-color: white;
                        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                        cursor: pointer;
                    }
                }
            }

            .pportal-tabs {
                // border: 1px solid black;
                height: max-content;
                margin: 0px 0px 0px 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 3px;

                .pportal-tabs-wrapper {
                    width: 300px;
                    height: 40px;
                    // border: 1px solid red;
                    // border-bottom: 1px solid #c5c5c5;
                    display: flex;

                    .pportal-tabs-item {
                        width: 50%;
                        // border: 1px solid red;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #e6e6e6;
                        color: #c5c5c5;
                        gap: 5px;
                        cursor: pointer;
                    }

                    .pportal-tabs-item.active {
                        border-bottom: 1px solid #2196f3;
                        color: #2196f3;
                    }

                }
            }

            .pportal-body-body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // border: 1px solid orange;

                .booking-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 10px;
                    gap: 15px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 100px;
                    position: relative;

                    .booking-item {
                        border: 1px solid #EEEEEE;
                        padding: 15px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 270px;
                        height: 135px;
                        position: relative;
                        background-color: white;

                        .booking-orderno {
                            background-color:#0f5bce;
                            // color: white;
                            color: #c4dff5;
                            width: max-content;
                            border-radius: 20px;
                            padding: 2px 10px;
                        }

                        .booking-service {
                            color: #8f8f8f;
                            font-weight: 500;
                            // border: 1px solid red;
                            width: 190px;
                            font-size: 13px;
                        }

                        .booking-date {
                            color: #757575;
                            font-size: 12px;
                        }

                        .booking-amount {
                            position: absolute;
                            margin: 35px auto 0px 190px;
                            font-size: 20px;
                        }

                        .booking-status {
                            position: absolute;
                            // margin: 65px auto 0px 205px;
                            margin: 110px auto 0px 0px;
                            font-size: 12px;
                            // border: 1px solid black;
                            padding: 5px;
                            border-radius: 5px;
                        }

                        .booking-status.success {
                            background-color: #b9f6ca;
                            color: #00c853;
                        }

                        .booking-status.pending {
                            background-color: #fff3e0;
                            color: #ff9800;
                        }

                        .booking-status.cancel {
                            background-color: #ffebee;
                            color: #f44336;
                        }

                        .booking-status.refund {
                            background-color: #e3f2fd;
                            color: #2196f3;
                        }

                        .booking-modal {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            padding: 5px;
                            border: 1px solid #2196f3;
                            color: #2196f3;
                            position: absolute;
                            margin: 105px auto 0px 235px;
                            cursor: pointer;
                        }

                    }

                }
            }
        }
    }
}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .pportal-container {
        display: flex;
        flex-direction: column;
    
        .pportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .pportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }

            .pportal-mobile {
                display: block;
            }
    
            .pportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .pportal-body-header {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;
                    height: 150px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    gap: 10px;
                    // border: 1px solid orange;
    
                    .name-space {
                        color: #0C4CAC;
                        font-size: 15px;
                        margin: 20px 0px 0px 0px;
    
                    }
                    .search-bar {
                        position: relative;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 270px;
                            padding: 15px 10px 15px 45px;
                            border-radius: 2em;
                            border: 1px solid #ececec;
    
    
                        }
                    }
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        display: none;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .pportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            // width: 270px;
                            width: 300px;
                            height: 135px;
                            position: relative;
                            background-color: white;
    
                            .booking-orderno {
                                background-color:#0f5bce;
                                // color: white;
                                color: #c4dff5;
                                width: max-content;
                                border-radius: 20px;
                                padding: 2px 10px;
                            }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 190px;
                                font-size: 13px;
                            }
    
                            .booking-date {
                                color: #757575;
                                font-size: 12px;
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 200px;
                                font-size: 20px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 110px auto 0px 0px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 5px;
                                border-radius: 5px;
                            }
    
                            .booking-status.success {
                                background-color: #b9f6ca;
                                color: #00c853;
                            }
    
                            .booking-status.pending {
                                background-color: #fff3e0;
                                color: #ff9800;
                            }
    
                            .booking-status.cancel {
                                background-color: #ffebee;
                                color: #f44336;
                            }
    
                            .booking-status.refund {
                                background-color: #e3f2fd;
                                color: #2196f3;
                            }
    
                            .booking-modal {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                padding: 5px;
                                border: 1px solid #2196f3;
                                color: #2196f3;
                                position: absolute;
                                margin: 105px auto 0px 265px;
                            }
    
                        }
    
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .pportal-container {
        display: flex;
        flex-direction: column;
    
        .pportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .pportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }

            .pportal-mobile {
                display: block;
            }
    
            .pportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .pportal-body-header {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;
                    height: 150px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    gap: 10px;
                    // border: 1px solid orange;
    
                    .name-space {
                        color: #0C4CAC;
                        font-size: 15px;
                        margin: 20px 0px 0px 0px;
    
                    }
                    .search-bar {
                        position: relative;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 270px;
                            padding: 15px 10px 15px 45px;
                            border-radius: 2em;
                            border: 1px solid #ececec;
    
    
                        }
                    }
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        display: none;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .pportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            // width: 270px;
                            width: 330px;
                            height: 135px;
                            position: relative;
                            background-color: white;
    
                            .booking-orderno {
                                background-color:#0f5bce;
                                // color: white;
                                color: #c4dff5;
                                width: max-content;
                                border-radius: 20px;
                                padding: 2px 10px;
                            }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 190px;
                                font-size: 13px;
                            }
    
                            .booking-date {
                                color: #757575;
                                font-size: 12px;
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 230px;
                                font-size: 20px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 110px auto 0px 0px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 5px;
                                border-radius: 5px;
                            }
    
                            .booking-status.success {
                                background-color: #b9f6ca;
                                color: #00c853;
                            }
    
                            .booking-status.pending {
                                background-color: #fff3e0;
                                color: #ff9800;
                            }
    
                            .booking-status.cancel {
                                background-color: #ffebee;
                                color: #f44336;
                            }
    
                            .booking-status.refund {
                                background-color: #e3f2fd;
                                color: #2196f3;
                            }
    
                            .booking-modal {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                padding: 5px;
                                border: 1px solid #2196f3;
                                color: #2196f3;
                                position: absolute;
                                margin: 105px auto 0px 295px;
                                cursor: pointer;
                            }
    
                        }
    
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 451px) and (max-width: 700px){ //Mini Tablet
    .pportal-container {
        display: flex;
        flex-direction: column;
    
        .pportal-wrapper {
            height: max-content;
            margin: 60px 0px 0px 0px;
            // border: 1px solid black;
            display: flex;
            flex-direction: row;
    
            .pportal-sidenav {
                height: 100%;
                width: 60px;
                display: none;
                // border: 1px solid blue;
            }

            .pportal-mobile {
                display: block;
            }
    
            .pportal-body {
                // height: 100vh;
                height: max-content;
                width: 100%;
                // border: 1px solid orange;
                background-color: #fafafa;
    
                .pportal-body-header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    height: 100px;
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                    // border: 1px solid orange;
    
                    .name-space {
                        color: #0C4CAC;
                        font-size: 20px;
                        width: 100%;
                        text-align: center;
    
                    }
                    .search-bar {
                        position: relative;
    
                        input:focus, textarea:focus, select:focus {
                            outline: #0C4CAC;
                            border: 1px solid #0C4CAC;
                        }
    
                        .search-icon {
                            position: absolute;
                            margin: 12px 0px 0px 12px;
                            color: #e0e0e0;
                        }
                        .formInput {
                            width: 220px;
                            padding: 15px 10px 15px 45px;
                            border-radius: 2em;
                            border: 1px solid #ececec;
    
    
                        }
                    }
                    .sorting {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        display: none;
                        // align-items: center;
                        // justify-content: center;
                        // border: 1px solid blue;
    
                        .sorting-grid {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            background-color: white;
                            color: #0C4CAC;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                        .sorting-list {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            // border: 1px solid blue;
                            border-radius: 50%;
                            // background-color: white;
                            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                            cursor: pointer;
                        }
                    }
                }
    
                .pportal-body-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // border: 1px solid orange;
    
                    .booking-wrapper {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
    
                        .booking-item {
                            border: 1px solid #EEEEEE;
                            padding: 15px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            width: 230px;
                            height: 135px;
                            position: relative;
                            background-color: white;
    
                            .booking-orderno {
                                background-color:#0f5bce;
                                // color: white;
                                color: #c4dff5;
                                width: max-content;
                                border-radius: 20px;
                                padding: 2px 10px;
                            }
    
                            .booking-service {
                                color: #8f8f8f;
                                font-weight: 500;
                                // border: 1px solid red;
                                width: 190px;
                                font-size: 13px;
                            }
    
                            .booking-date {
                                color: #757575;
                                font-size: 12px;
                            }
    
                            .booking-amount {
                                position: absolute;
                                margin: 35px auto 0px 160px;
                                font-size: 17px;
                            }
    
                            .booking-status {
                                position: absolute;
                                // margin: 65px auto 0px 205px;
                                margin: 110px auto 0px 0px;
                                font-size: 12px;
                                // border: 1px solid black;
                                padding: 5px;
                                border-radius: 5px;
                            }
    
                            .booking-status.success {
                                background-color: #b9f6ca;
                                color: #00c853;
                            }
    
                            .booking-status.pending {
                                background-color: #fff3e0;
                                color: #ff9800;
                            }
    
                            .booking-status.cancel {
                                background-color: #ffebee;
                                color: #f44336;
                            }
    
                            .booking-status.refund {
                                background-color: #e3f2fd;
                                color: #2196f3;
                            }
    
                            .booking-modal {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                padding: 5px;
                                border: 1px solid #2196f3;
                                color: #2196f3;
                                position: absolute;
                                margin: 105px auto 0px 205px;
                            }
    
                        }
    
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 701px) and (max-width: 960px){ //Mini Tablet

}